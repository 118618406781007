// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from './../../utilities/fetching';
import InstagramFeed from '../../components/blocks/public/sub-blocks/instagram-feed';

// View components

const InstagramFeedContainer = (props) => {

    // State management
    const [imgs, setImgs] = useState(false);
    const [error, setError] = useState(false);
    const [lftPosn, setLftPosn] = useState(0);
    const [imgNo, setImgNo] = useState(0);
    const [displayHandles, setDisplayHandles] = useState(false);
    const imgWidth = 170;
    const imgMargin = 12;

    const limit = 24;

    const loadImgs = () => {

        // Call the server to generate the token in the back-end and store it
        fetch(process.env.REACT_APP_API_BASE + '/instagram-feed/' + limit, {
            method: 'GET',
            headers: {
                "Content-type": "application/json"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setImgs(data.detail);
                setImgNo(data.detail.length);
                setTimeout(() => {
                    checkWidth(data.detail.length);
                }, 250);
                return
            }
            setError("No data to display.");

        })
        .catch(function (error) {
            setError("No data to display.");
            console.log(error);
        });

    }

    // Check the width of the slider and whether the handles should display or not
    const checkWidth = (imgNo) => {

        // Work out the total width of all images in the slider
        let totalWidth = (imgNo * imgWidth) + (imgNo * imgMargin * 2);

        // Figure out the width of the bounding rectangle
        var boundingDiv = document.getElementById("slideWrap");
        var boundingWidth = boundingDiv.offsetWidth;

        // If the total width is greater than the bounding width then display the handles
        if (totalWidth > boundingWidth) {
            setDisplayHandles(true);
        } else {
            setDisplayHandles(false);
        }

    }

    // Update the slider position
    const updateLftPosn = (slideChange) => {

        // Work out the total width of all images in the slider
        let totalWidth = (imgNo * imgWidth) + (imgNo * imgMargin * 2);

        // Figure out the width of the bounding rectangle
        var boundingDiv = document.getElementById("slideWrap");
        var boundingWidth = boundingDiv.offsetWidth;

        // What should the new left position be?
        let newLftPosn = lftPosn - (slideChange * (imgWidth + imgMargin * 2));

        // What is the furthest left that is allowed
        let furthestLeft = -(totalWidth - boundingWidth);

        // Handle the actual movement of the slider
        if (slideChange > 0 && lftPosn <= furthestLeft) {
            // If we are currently at the end of the row then skip to the start again
            newLftPosn = 0;
        } else if (slideChange < 0 && lftPosn >= 0) {
            // If we are at the start and trying to go back, then skip to the end
            newLftPosn = furthestLeft;
        } else if (newLftPosn > 0) {
            // If a back increment would take us past the beginning of the slider then just take us to the beginning
            newLftPosn = 0;
        } else if (newLftPosn < furthestLeft) {
            // If doing a full increment would take us past the end of the slides, then just take us to the end of the slider
            newLftPosn = furthestLeft;
        }
        // If none of the above conditions apply, then just move the slider to the newPosn

        // Update the slider position
        setLftPosn(newLftPosn);

    }

    /* eslint-disable */
    useEffect(() => {
        loadImgs();
    }, []);
    /* eslint-enable */

    return (
        <InstagramFeed
            imgs={imgs}
            error={error}
            updateLftPosn={updateLftPosn}
            lftPosn={lftPosn}
            displayHandles={displayHandles}
            {...props}
        />
    );
}

export default InstagramFeedContainer;