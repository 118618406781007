import InstagramFeedContainer from '../../../containers/pages/instagram-feed-container';
import ExtractContent from './../../../utilities/extract-content';

function Instagram(props) {

    

    let content = ExtractContent.block(props.block);
    if (!content) return "";

    return (
        <section className="instagram">
            <div className="content">
                { content.title && content.title !== "" &&
                    <h3>{ content.title }</h3>
                }

                <InstagramFeedContainer isAdmin={false} />

                {content.tag && content.tag !== "" &&
                    <p>{content.tag}</p>
                }
            </div>
        </section>
    );
}

export default Instagram;
