// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from './../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from './../../../store/flash';

// View components
import BatchCreate from './../../../components/admin/users/batch-create';

const BatchCreateContainer = (props) => {

    let initialBtnText = "Create Users"

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [rdr, setRdr] = useState(false);
    const [error, setError] = useState(false);
    const [btnTxt, setBtnTxt] = useState(initialBtnText);
    const [returnLinks, setReturnLinks] = useState(false)
    const [csv, setCsv] = useState(false)
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie] = useCookies(['lwb']);

    // Handle the drop of the file
    const handleFile = (e) => {
        setCsv(e.target.files[0]);
    }

    // Submit the details for saving
    const createUsers = ( e ) => {

        e.preventDefault();
        setError(false);

        setBtnTxt("Creating users...");

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['lwb'] == "undefined" || authCookie['lwb'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        var url = '/users/upload';
        // Set up the upload data
        var data = new FormData();
        data.append("csv", csv);
        var retVal = (returnLinks) ? "1" : "0";
        data.append("returnLinks", retVal)

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'post',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: data
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
          
            setBtnTxt("Create users");
            if(data.rslt === 'success' ){

                // Set up the result message
                var errorMsg = { msg: "The CSV file has been processed successfully.", style: "mbtm grn" };

                if (typeof data.detail != "undefined") {

                    // The CSV will contain the response data 
                    errorMsg = { msg: "The CSV file has been processed successfully.", style: "mbtm grn" };

                    // Handle the CSV file 
                    if(data.detail.length > 1) {
                        let csvContent = "data:text/csv;charset=utf-8,"
                            + data.detail.map(e => e.join(",")).join("\n");

                        var encodedUri = encodeURI(csvContent);
                        window.open(encodedUri);

                        // Update the error message
                        if (!returnLinks) {
                            errorMsg.msg = "The CSV file has been processed successfully. However, there were problems creating one or more or the users. Please check the resulting CSV file for these errors.";
                        } else {
                            errorMsg.msg = "The CSV file has been processed successfully. Please check the downloaded CSV file for the registration links and any errors.";
                        }
                    }
                } 
                
                setError(errorMsg);
                setCsv(false);
                setReturnLinks(false);
                return;
            } 
            setError({ msg: "Sorry but there was an error creating the users. Please check your CSV file format and try again", style: "mbtm red" });


        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error creating the users. Please check your data and try again", style: "mbtm red" });
        });

    }

    /* eslint-disable */
    useEffect(() => {
        document.title = 'Create users :: TechAssure';
    },[])
    /* eslint-enable */

    return (
        <BatchCreate
            error={ error }
            rdr={ rdr }
            handleFile={ handleFile }
            setReturnLinks={ setReturnLinks }
            returnLinks={returnLinks}
            btnTxt={ btnTxt }
            csv={csv}
            createUsers={ createUsers }
        />
    );
}

export default BatchCreateContainer;
