import { Fragment } from 'react';
import {Link} from 'react-router-dom';
import NewsletterSignupContainer from '../../../containers/common/newsletter-signup-container';
import DisplayImageContainer from '../../../containers/images/display-image-container';
import ExtractContent from './../../../utilities/extract-content';
import DonateAdvert from './sub-blocks/donate';

function ThirdResponses(props) {

    let content = ExtractContent.block(props.block);
    if (!content) return "";

    return (
        <section className="half-responses three">
            
            <div className="content">
                <DonateAdvert class="width-4 mtop-96 mbtm-96" />

                <div className="width-4 response">
                    {content["img-1"] && content["img-1"] !== "" &&
                        <DisplayImageContainer
                            imageId={content["img-1"]}
                            size="response"
                            class="head-img"
                            isBgImage={true}
                        />
                    }

                    { content["title-1"] && content["title-1"] !== "" &&
                        <h3>{ content["title-1"] }</h3>
                    }
                    {content["content-1"] && content["content-1"] !== "" &&
                        <div dangerouslySetInnerHTML={{ __html: content["content-1"]}}></div>
                    }

                    {content["action-1"] && content["action-1"] === "newsletter" ? (
                        <NewsletterSignupContainer />
                    ) : (
                        <Fragment>
                            { typeof content["action-link-1"] !== "undefined" && typeof content["action-txt-1"] !== "undefined" && content["action-link-1"] !== "" && content["action-txt-1"] !== "" &&
                                <Link to={content["action-link-1"]} className="btn">{content["action-txt-1"] }</Link>
                            }
                        </Fragment>
                    )}
                </div>

                <div className="width-4 response">
                    {content["img-2"] && content["img-2"] !== "" &&
                        <DisplayImageContainer
                            imageId={content["img-2"]}
                            size="response"
                            class="head-img"
                            isBgImage={true}
                        />
                    }

                    {content["title-2"] && content["title-2"] !== "" &&
                        <h3>{content["title-2"]}</h3>
                    }
                    {content["content-2"] && content["content-2"] !== "" &&
                        <div dangerouslySetInnerHTML={{ __html: content["content-2"] }}></div>
                    }

                    {content["action-2"] && content["action-2"] === "newsletter" ? (
                        <NewsletterSignupContainer />
                    ) : (
                        <Fragment>
                            { typeof content["action-link-2"] != "undefined" && typeof content["action-txt-2"] != "undefined" && content["action-link-2"] !== "" && content["action-txt-2"] !== "" &&
                                <Link to={content["action-link-2"]} className="btn">{content["action-txt-2"]}</Link>
                            }
                        </Fragment>
                    )}
                </div>
            </div>
        </section>
    );
}

export default ThirdResponses;
