// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../../store/flash';

// View components
import ChangeDetails from './../../../../components/admin/users/auth/change-details';
const ChangeDetailsContainer = (props) => {


    let isUserEdit = true;
    let initialBtnText = "Save"

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [rdr, setRdr] = useState(false);
    const [error, setError] = useState(false);
    const [btnTxt, setBtnTxt] = useState(initialBtnText);
    const [settings, setSettings] = useState({ firstName: "", lastName: "", email: "" }); // eslint-disable-line
    const [permissions, setPermissions] = useState({ Reports: false, Users: false, Questionnaire: false, Clients: false }); // eslint-disable-line
    const [returnEmailLink, setReturnEmailLink] = useState(false); // eslint-disable-line
    const [valErrors, setValErrors] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie] = useCookies(['lwb']);
    // const [managerId,setManagerId] = useState(-1)
    const [clientId] = useState(-1)
    const [userManager,setUserManager] = useState({})

    const [emailLink,setEmailLink] = useState(false)
    const [lightBoxOpen,toggleLightBoxOpen] = useState(false)

    // Handle the field updates 
    const handleUpdate = (fieldname, val) => {
        var newSettings = { ...settings };
        newSettings[fieldname] = val;
        setSettings(newSettings);
    }

    // Submit the details for saving
    const saveUser = ( e ) => {

        e.preventDefault();
        setError(false);
        setValErrors({})

        var nbtn = "Saving...";
        setBtnTxt(nbtn);

        let dataToPost = {
            password:settings.password,
            firstName:settings.firstName,
            lastName:settings.lastName,
            email:settings.email,
            phone:settings.phone,
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['lwb'] == "undefined" || authCookie['lwb'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // var email = encodeURI(settings.email_address.replace("+", "*PLUS*"));

        var url = '/users/update'  ;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(dataToPost)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if(data.rslt === 'success' ){
                if( data.detail != null){
                    setEmailLink(data.detail)
                    toggleLightBoxOpen(true)
                } else {

                    let message = 'Your account has been updated'

                    setError({ msg: message, style: "grn" });
                }
                
            } else if (data.rslt === 'unapproved'){
                setError({ msg: "Sorry, please ensure the user details are filled out correctly", style: "red" });
                setValErrors(
                    {
                        'password':['Please enter the correct password'],
                   }
                );

            } else if (data.rslt === 'usr-valid-error' || data.rslt === "invalid-data"){
                setError({ msg: "Sorry, please ensure the user details are filled out correctly", style: "red" });
                
            }else if (data.rslt === 'save-error'){
                setError({ msg: "Sorry, there was an error saving on the server", style: "red" });
                
            } else if (data.rslt === 'usr-exists'){
                setError({ msg: "Sorry, there was an error saving", style: "red" });
                setValErrors(
                    {
                        'email':['This email address already exists in an account'],
                   }
                );
            } else {
                setError({ msg: "Sorry, there was an error saving", style: "red" });
            }

            var nbtn = "Save";

            setBtnTxt(nbtn);


        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error saving the user. Please check your data and try again", style: "red" });
            var nbtn = "Save";

            setBtnTxt(nbtn);
        });

    }

    // Load up existing user details
    const loadUsr = () => {

        // return;

        setLoading(true);

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['lwb'] == "undefined" || authCookie['lwb'] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }

        // Get the user details from the server
        var reformData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8)); 
        
        fetch(process.env.REACT_APP_API_BASE + '/users/me' , {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
 
            let newSettings = {
                phone:data.MobileNo,
                email:data.Email,
                firstName:data.FirstName,
                lastName:data.LastName
            }
            setSettings(newSettings)

            setLoading(false);

        })
        .catch(function (error) {
            setError({ msg: "Sorry but we could not find the user you are looking for. PLease try again.", style: "red" });
            // setRdr('/users');
        });        
    }

    /* eslint-disable */
    useEffect(() => {
        loadUsr()
        document.title = 'Edit Account :: TechAssure';
    },[]);
    /* eslint-enable */

    return (
        <ChangeDetails
            error={ error }
            valErrors={ valErrors }
            rdr={ rdr }
            // loading={ loading }

            handleUpdate={ handleUpdate }

            saveUser={saveUser}
            setReturnEmailLink={setReturnEmailLink}
            clientId={clientId}
            permissions={permissions}
            settings={settings}
            userManager={userManager}
            setUserManager={setUserManager}
            // groups={ groups }
            // title={ title }
            isUserEdit={isUserEdit}
            btnTxt={ btnTxt }
            emailLink={emailLink}
            lightBoxOpen={lightBoxOpen}
            toggleLightBoxOpen={toggleLightBoxOpen}
            // timezones={ timezones }
        />
    );
}

export default ChangeDetailsContainer;
