import ExtractContent from './../../../utilities/extract-content';

function VideoAdmin(props) {

    let content = ExtractContent.block(props.block, "draft");

    return (
        <section className="social-bar">

            <div className="content">

                <div className="width-12 centred video">

                    <div className="input text">
                        <label htmlFor="video-code">Video embed code</label>
                        <input id="video-code" type="text" className="white mbtm-big" placeholder="Video embed code" defaultValue={content.code} onInput={(e) => props.updateField("code", e.target.value)} onBlur={() => props.saveBlock()} />
                    </div>
                    
                    
                </div>

            </div>
        </section>
    );
}

export default VideoAdmin;
