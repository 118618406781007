import React, { useState, useEffect } from 'react';

import Fetching from './../../utilities/fetching';
import Contact from '../../components/common/contact';


function ContactContainer(props) {

    // Set up the state to track status 
    const [message, setMessage] = useState({ firstName: "", lastName: "", email: "", phone: "", message: "", receiveNewsletter: false });
    const [btnTxt, setBtnTxt] = useState("Send message");
    const [error, setError] = useState(false);
    const [valErrors, setValErrors] = useState(false);

    // Update the fields in the form 
    const updateField = (fieldName, value) => {

        // Update the state
        var newMessage = {...message};
        newMessage[fieldName] = value;
        setMessage(newMessage);

    }

    // Load up the article content
    const sendMessage = (e) => {

        e.preventDefault();
        setBtnTxt("Sending message...");

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/signup/contact', {
            method: 'post',
            headers: reqHeaders,
            body: JSON.stringify(message)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                setError({ msg: "Your message has been sent successfully. We will get back to you very soon.", style: "grn" });
                setValErrors(false);
            } else {
                setError({ msg: "Sorry, there was an error sending your message. Please check the message and try again.", style: "red" });
                var valErrs = {};
                for (var i in data.detail) {
                    valErrs[data.detail[i].FieldName] = data.detail[i].Error;
                }
                setValErrors(valErrs);
            }
            setBtnTxt("Send message");

        })
        .catch(function (error) {
            setBtnTxt("Send message");
        });

    }

    return (
        <Contact 
            btnTxt={btnTxt}
            message={message}
            updateField={updateField}
            sendMessage={sendMessage}
            error={error}
            valErrors={valErrors}
        />
    );
}

export default ContactContainer;
