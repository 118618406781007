// Router
import { Outlet, useLocation } from "react-router-dom";

// Components
import Footer from './common/footer';
import HeaderSlidesContainer from "../containers/common/header-slides-container";

function PageTemplate(props) {

    const location = useLocation();

    return (
        <div className="main">
            { location.pathname === "/" &&
                <HeaderSlidesContainer />
            }
            
            <Outlet />
            <Footer />
        </div>
    );
}

export default PageTemplate;
