import {Link} from 'react-router-dom';

import ExtractContent from './../../../utilities/extract-content';

import FacebookIcon from './../../../assets/img/lw/facebook.png';
import TwitterIcon from './../../../assets/img/lw/twitter.png';
import LinkedinIcon from './../../../assets/img/lw/linkedin.png';
import InstagramIcon from './../../../assets/img/lw/instagram.png';

function SocialBar(props) {

    let content = ExtractContent.block(props.block, "draft");

    return (
        <section className="social-bar">

            <div className="content">

                <div className="width-12 centred">
                    <h3>
                        <input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content.title} onInput={(e) => props.updateField("title", e.target.value)} onBlur={() => props.saveBlock()} />
                    </h3>
                    
                    <ul className="social-icons">
                        <li>
                            <a href="https://www.facebook.com/livingwellbromleyfoodbank" target="_blank" rel="noreferrer"><img src={FacebookIcon} alt="Share this on Facebook" className="fb" /></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/LWBromley" target="_blank" rel="noreferrer"><img src={TwitterIcon} alt="Share this on Twitter" /></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/livingwellbromley" target="_blank" rel="noreferrer"><img src={InstagramIcon} alt="Share this on Instagram" /></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/living-well-bromley1" target="_blank" rel="noreferrer"><img src={LinkedinIcon} alt="Share this on LinkedIn" /></a>
                        </li>
                    </ul>
                </div>

            </div>
        </section>
    );
}

export default SocialBar;
