// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';

// View components
import Signups from '../../../components/admin/reports/signups';

const SignupsContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;

    const [page, setPage] = useState(0);
    const [signups, setSignups] = useState([]);
    const [error, setError] = useState(false);
    const [authCookie] = useCookies(['lwb']);
    const limit = 25;

    const navigate = useNavigate();

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadSignups = () => {

        if (!page && page !== 0) {
            // There is nothing left to load
            return;
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.lwb == "undefined" || authCookie.lwb === "undefined") {
            // Not logged in so everything is cool
            navigate("/login");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.lwb, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let existing = signups.length; 

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/signups/' + existing + '/' + limit, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success' && data.detail.length > 0) {
                var su = signups.concat(data.detail);
                setSignups(su);

                // Get the last ID to set the offset
                if (data.detail.length === limit) {
                    let lastItem = data.detail[data.detail.length - 1];
                    setPage(lastItem.id);
                } else {
                    setPage(false);
                }
                return;
            }

        })
        .catch(function (error) {
            navigate("/login");
            console.log(error);
        });
    }

    // Check that we have a partially complete auth status (based upon the cookie)
    let exportSignups = (e) => {
        e.preventDefault();

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.lwb == "undefined" || authCookie.lwb === "undefined") {
            // Not logged in so everything is cool
            navigate("/login");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.lwb, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));


        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/signups/csv', {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                generateCsv(data.detail, "user-signups.csv");
            }

        })
        .catch(function (error) {
            // navigate("/login");
            console.log(error);
        });
    }

    const handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) >= (e.target.clientHeight - 20);
        if (bottom) {
            loadSignups();
        }
    }

    const generateCsv = (data, filename) => {
        var csv = "Email address,Data protection permissons granted,Created,Last modified";
        for (var i in data) {
            csv += data[i].email + "," + data[i].PermissionsGranted + "," + data[i].CreatedAt + "," + data[i].UpdatedAt + "\n";
        }
        downloadCSV(csv, filename);
    }

    const downloadCSV = (csv, filename) => {
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const togglePermissions = (e, id) => {

        e.preventDefault();
        setError(false);

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie.lwb == "undefined" || authCookie.lwb === "undefined") {
            // Not logged in so everything is cool
            // flashActions.set({ msg: "Sorry but we could not find the article you are looking for. Please sign in and try again.", style: "red" });
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie.lwb, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/signups/permissions/' + id, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                var newSignups = [...signups];
                for (var i in newSignups) {
                    if (newSignups[i].id === id) {
                        newSignups[i].PermissionsGranted = data.detail;
                    }
                }
                setSignups(newSignups);
            }

        })
        .catch(function (error) {
                // navigate(loginUrl);
            return;
        });

    }

    /* eslint-disable */
    useEffect(() => {
        loadSignups();
    }, []);
    /* eslint-enable */

    return (
        <Signups
            signups={signups}
            handleScroll={handleScroll}
            exportSignups={exportSignups}
            togglePermissions={togglePermissions}
        />
    );
}

export default SignupsContainer;