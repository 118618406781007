import {Link} from 'react-router-dom';
import WordingContainer from '../../../../containers/common/wording/wording-container';

function DonateAdvert(props) {

    return (
        <div className={ typeof props.class != "undefined" ? props.class + " donate-advert" : "donate-advert"}>
            <div>
                <h3>Support our work</h3>

                <div>
                    <div className="option">
                        <Link to="/p/donate/10">Donate &pound;10 now</Link>
                        <span><span>&pound;10</span> a month</span>
                        <p><WordingContainer wordName="donate10" default="Pays for a food parcel for one adult" /></p>
                    </div>

                    <div className="option">
                        <Link to="/p/donate/20">Donate &pound;20 now</Link>
                        <span><span>&pound;20</span> a month</span>
                        <p><WordingContainer wordName="donate25" default="Pays for a food parcel for two children" /></p>
                    </div>

                    <div className="option">
                        <Link to="/p/donate/50">Donate &pound;50 now</Link>
                        <span><span>&pound;50</span> a month</span>
                        <p><WordingContainer wordName="donate50" default="Will give 25 people a home-cooked meal" /></p>
                    </div>
                </div>

                <Link to="/p/donate" className="btn">Donate now</Link>
            </div>
        </div>
    );
}

export default DonateAdvert;
