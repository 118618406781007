import ExtractContent from './../../../utilities/extract-content';

function PlainTextBlue(props) {

    let content = ExtractContent.block(props.block);

    return (
        <section className="plain-txt-blue">

            <div className="content">

                <div className="width-8 centred">
                    {typeof content["title"] !== "undefined" && content["title"] !== "" &&
                        <h3>{content.title}</h3>
                    }

                    { typeof content["content"] !== "undefined" && content["content"] !== "" &&
                        <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
                    }
                </div>

            </div>
        </section>
    );
}

export default PlainTextBlue;
