import InstagramFeedContainer from '../../../containers/pages/instagram-feed-container';
import ExtractContent from './../../../utilities/extract-content';

function Instagram(props) {

    let content = ExtractContent.block(props.block, "draft");


    return (
        <section className="instagram">
            <div className="content">
                <h3>
                    <input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content.title} onInput={(e) => props.updateField("title", e.target.value)} onBlur={() => props.saveBlock()} />
                </h3>

                <InstagramFeedContainer isAdmin={true} />

                <p>
                    <input type="text" className="white centre mtop-big" placeholder="Instagram tag..." defaultValue={content.tag} onInput={(e) => props.updateField("tag", e.target.value)} onBlur={() => props.saveBlock()} />
                </p>

            </div>
        </section>
    );
}

export default Instagram;

/*
Instagram dots
<ul className="dots">
                    <li className="current"><button></button></li>
                    <li><button></button></li>
                    <li><button></button></li>
                    <li><button></button></li>
                </ul>

                */