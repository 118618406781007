import React, { Fragment } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Error from '../common/forms/error';
import moment from "moment";
import Loader from '../common/loader';

function UserList(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    const getPasswordResetLightboxContent = (passwordResetUrl) => {
        let lightboxViewContent = <Fragment>
            <p>You can send the link below to this user so they can reset their password for their account.</p>
            <p className="lnk-wrap">{passwordResetUrl}</p>
        </Fragment>
        
        return lightboxViewContent
    }
    
    let lbContent = null
    let lightboxViewTitle = '';
    let lightboxViewContent = null;

    if(props.lightboxView === 'resetPassword'){
        lightboxViewTitle = 'User password reset'
        lightboxViewContent =   getPasswordResetLightboxContent(props.passwordResetUrl)
    }
    
    if(props.lightBoxOpen){
        lbContent = <div className="cover">
            <div className="hover-box">
                <Link to='' onClick={(e) => props.closeLightbox(e)} className="close-lnk">Close</Link>
                <h2>{lightboxViewTitle}</h2>
                { props.lbError && 
                    <div className={ props.lbError.class }>
                        <p>{ props.lbError.msg }</p>
                    </div>
                }
                {lightboxViewContent}
            </div>
        </div>
    }
    
    return (
        <Fragment>
            <div className="width-3 sidebar filter">
                <h4>Filter users</h4>

                <div className="input text">
                    <label htmlFor='searchInput'>User name</label>
                    <input type="text" id="searchInput" onChange={(e) => props.setSearchEntry(e.target.value)} />
                </div>

                <button onClick={() => props.loadUsers("search")}>Filter</button>
            </div>

            <div className='width-9'>
                {/*props.userPermissions.Users && <Link to='/admin/manage/users/upload' className="btn minor faded">Upload user CSV</Link>*/}
                {props.userPermissions.Users && 
                    <Link to='/admin/users/create' className="right btn minor">Create user</Link>
                }
                <h1>Manage users</h1>

                <div>
                    {lbContent}

                    <Error error={props.error}/>

                    <div className="table-wrap" onScroll={props.handleScroll}>
                        <table>
                            <thead>
                                <tr>
                                    <th>First name</th>
                                    <th>Last name</th>
                                    <th className='med-hide'>Email</th>
                                    <th className='big-hide'>Created</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                { typeof props.users != "undefined" && props.users.map( (user,i) => {
                                    return (
                                        <tr key={'tr-'+i}>
                                            <td>{user.FirstName}</td>
                                            <td>{user.LastName}</td>
                                            <td className='med-hide'>{user.Email}</td>
                                            <td className='big-hide'>{ moment(user.Created).format("DD/MM/YYYY") }</td>
                                            <td>
                                                {!user.hideLink && props.userPermissions.Users ? <Link to='' onClick={(e) => props.resetUsrPw(user.Ref, e)} className="action-btn big-hide">Password</Link>: null }
                                                {!user.hideLink && props.userPermissions.Users ? <Link to={'/admin/users/'+user.Ref} className="action-btn sml-hide">Edit</Link> : null }
                                                {!user.hideLink && props.userPermissions.Users ? <Link to='' onClick={(e) => props.deleteUser(user.Email, e)} className="action-btn red sml-hide">Delete</Link>: null }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {props.loading &&
                            <Loader />
                        }
                    </div>
                        
                </div>

            </div>
        </Fragment>
    )
}

export default UserList