import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import MenusContainer from '../../containers/pages/menus-container';
import LwLogo from './../../assets/img/lw/livingwell-logo.svg'
import Tiles from './tiles';
import DisplayImageContainer from './../../containers/images/display-image-container';

function HeaderIntro(props) {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    return (
        <section className="leader story">
            <header className={menuOpen ? "mobile-open" : ""}>
                <div className="logo left">
                    <a href="/">
                        <img src={ LwLogo } alt="Living Well logo" />
                    </a>
                </div>
                <nav className={ menuOpen ? "main centred open" : "main centred"}>
                    <button className="mobile-menu menu-toggle" onClick={(e) => toggleMenu(e)}>Menu</button>
                    <MenusContainer menuSlug="main" setMenuOpen={setMenuOpen} />
                </nav>
            </header>

            <section className="content slideshow">
                <div className="width-4 description">
                    <h2>{ props.page.subtitle }</h2>
                    <h1>From despair to repair</h1>
                    <p>{ props.page.intro }</p>
                </div>

                {typeof props.page.imageId != "undefined" && props.page.imageId !== 0 &&
                    <div className="width-8 imgs">
                        <DisplayImageContainer
                            imageId={props.page.imageId}
                            size="head"
                            class="ss-img active"
                            isBgImage={true}
                        />
                    </div>
                }
            </section>

            <Tiles no={4} size="big" direction="down"/>
        </section>
    );
}

export default HeaderIntro;
