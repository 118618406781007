import React, { useState, useEffect } from 'react';

import Fetching from '../../utilities/fetching';
import NewsletterSignup from '../../components/common/newsletter-signup';


function NewsletterSignupContainer(props) {

    // Set up the state to track status 
    const [email, setEmail] = useState("");
    const [btnTxt, setBtnTxt] = useState("Sign up");
    const [error, setError] = useState(false);


    // URL slugs

    // Load up the article content
    const signup = (e) => {

        e.preventDefault();
        setBtnTxt("Signing up...");

        var data = {
            email: email,
            signupFor: "newsletter",
            permissionsGranted: true
        }

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/signup', {
            method: 'post',
            headers: reqHeaders,
            body: JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt("Sign up");

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                
                setError({ msg: "Thanks for signing up.", style: "grn" });

            } else {
                setError({ msg: "Sorry but there was an error submitting this email address. Please check it and try again." });
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error storing this email address. Please check it and try again." });
            setBtnTxt("Sign up");
        });

    }

    return (
        <NewsletterSignup
            signup={signup}
            error={error}
            btnTxt={btnTxt}
            setEmail={setEmail}
        />
    );
}

export default NewsletterSignupContainer;
