import React, { Fragment } from 'react';
import ValidationError from '../../../common/validation-error';

function TextInput(props){

    return (
        <Fragment>
            <div className="input text">
                <label htmlFor={props.id}>{props.label}</label>
                <input type="text" id={props.id} autoComplete={props.id} onChange={(e) => props.handleChange(props.fieldName, e.target.value) } defaultValue={props.value} />
                <ValidationError errors={props.valErrors} fieldName={props.valName} />    
            </div>
        </Fragment>
    )
}

export default TextInput
