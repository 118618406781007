import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import Fetching from '../../utilities/fetching';
import GiftAid from '../../components/pages/giftaid';

function GiftAidContainer(props) {

    // Manage state fo rthe donations as a whole
    const [valErrors, setValErrors] = useState(false);
    const [amount, setAmount] = useState(false);
    const [error, setError] = useState(false);
    const [complete, setComplete] = useState(false);
    // const [type, setType] = useState("one-off");
    const [pi, setPi] = useState(false);
    const [userDetails, setUserDetails] = useState({ firstName: "", lastName: "", address1: "", address2: "", address3: "", postcode: "", country: "", message: "", giftAid: false, newsletter: false});

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    // Handle the update of user details 
    const setUserDetail = (fieldName, value) => {

        var uds = {...userDetails}
        if (typeof uds[fieldName] != "undefined") uds[fieldName] = value;
        setUserDetails(uds);

    }

    // Check the odnation details (based upon a parsed PI)
    const checkStatus = async () => {

        // Get the payment intent from the URL
        let urlPi = searchParams.get('payment_intent');
        let urlPiSec = searchParams.get('payment_intent_client_secret');
        let status = searchParams.get('redirect_status');
        let gc = searchParams.get('gc');
        
        // Check that this is a valid redirect 
        if ((pi === "" || status !== "succeeded") && gc !== "payment-success" && gc !== "sub-success") {
            navigate("/donations/failure");
            return 
        }
        setPi(urlPi);

        var url = process.env.REACT_APP_API_BASE + '/payments/gift-aid/' + urlPi + "/" + urlPiSec + "/" + status;
        if (gc === "payment-success" || gc === "sub-success") url = process.env.REACT_APP_API_BASE + '/payments/gift-aid/' + urlPi + "/gocardless/" + gc;

        // Make a backend request to trigger the donation and store the details
        fetch(url, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Return the result
            if (data.rslt === "success") {
                let usrData = { ...userDetails };
                usrData.firstName = data.detail.FirstName;
                usrData.lastName = data.detail.LastName;
                usrData.address1 = data.detail.Address1;
                usrData.address2 = data.detail.Address2;
                usrData.address3 = data.detail.Address3;
                usrData.postcode = data.detail.Postcode;
                usrData.country = data.detail.Country;
                setUserDetails(usrData);
                console.log(usrData);
                setAmount(data.detail.Amount);
                setError({ msg: "Thanks for donating to Living Well. Please provide your details below so we can claim Gift Aid on your donation.", style: "grn mtop" });
                return;
            }

            // The donation doesn't appear valid, redirect back to the donations page
            navigate("/donations/failure");
            
        })
        .catch(function (error) {
            navigate("/donations/failure");
        });

    }
    
    // Handle the Gift Aid information storage
    const saveData = (e) => {
        e.preventDefault();

        let urlPi = searchParams.get('payment_intent');
        let gc = searchParams.get('gc');

        // Set up the data 
        var saveData = { ...userDetails }
        saveData.paymentRef = searchParams.get('payment_intent_client_secret');
        saveData.provider = "stripe";
        if (gc === "payment-success" || gc === "sub-success") {
            saveData.paymentRef = urlPi;
            saveData.provider = "gocardless";
        }

        // Make a backend request to trigger the donation and store the details
        fetch(process.env.REACT_APP_API_BASE + '/payments/gift-aid', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Return the result
            if (data.rslt === "success") {
                setError({ msg: "Thanks for donating to Living Well. Please provide your details below so we can claim Gift Aid on your donation.", style: "grn mtop" });
                setComplete(true);
                return;
            }

            // Process and display the errors
            setError({ msg: "Sorry but there was an error saving your details. Please check the errors below and try again.", style: "red mtop" });
            if (data.rslt === "validate-error") {
                let valErrs = Fetching.processErrors(data.detail);
                setValErrors(valErrs);
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error saving your details. Please check the data and try again.", style: "red mtop" });
        });

    }

    /* eslint-disable */
    useEffect(() => {

        checkStatus();

    }, []);
    /* eslint-enable */

    return (
        <GiftAid 
            userDetails={ userDetails }
            setUserDetail={ setUserDetail }
            amount={ amount }
            saveData={ saveData }
            error={ error }
            complete={ complete }
            valErrors={ valErrors }
        />
    );
}

export default GiftAidContainer;