import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

import Tiles from '../../common/tiles';
import ExtractContent from './../../../utilities/extract-content';
import DonateAdvert from './../public/sub-blocks/donate';

function InfoDonate(props) {

    let content = ExtractContent.block(props.block, "draft");

    return (
        <section className="info">
            <Tiles no={ 4 } direction="down" size="med" posn="left" />

            <div className="content">

                <div className="half">
                    <h2>
                        <input type="text" className="h1Input mtop-big" placeholder="Title..." defaultValue={content.title} onInput={(e) => props.updateField("title", e.target.value)} onBlur={() => props.saveBlock()} />
                    </h2>

                    <CKEditor
                        editor={InlineEditor}
                        data={content.content ? content.content : "Enter content..."}
                        config={{
                            toolbar: ['heading', '|', 'Bold', 'Italic', 'Link', "Unlink", "|", 'NumberedList', 'BulletedList', 'Blockquote', '|', 'undo', 'redo'],
                            heading: {
                                options: [
                                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                                ]
                            }
                        }}
                        onBlur={(event, editor) => {
                            const data = editor.getData();
                            props.updateField('content', data, true)
                        }}
                    />
                </div>

                <DonateAdvert content={content} class="second-half" />
            </div>
        </section>
    );
}

export default InfoDonate;
