// Core react and plugins
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import ImagesContainer from '../../../containers/admin/images/images-container';
import Checkbox from '../common/forms/checkbox';
import ImageInput from '../images/image-input';

// Components
import Error from './../common/forms/error';
import ValidationError from './../common/forms/validation-error';
import Loader from './../common/loader';


// Component class itself
class Page extends Component {

    render() {

        const {
            title,subTitle,intro,slug,isPublished,template
        } = this.props;

        if (this.props.loading) {
            return <Loader />;
        }
        let saveBtnText= 'Update Page';
        let savingBtnText = 'Updating Page...';
        let pageTitle = 'Update Page';

        if(this.props.createTierPage){
            saveBtnText= 'Create Page';
            savingBtnText = 'Creating Page...';
            pageTitle = 'Create Page';
        }

        let templateOptions = [
            {value:'home',text:'Home'}, 
            {value:'info',text:'Plain'}
        ]

        return (

            <div className="width-6 centred">
                <Helmet>
                    <title>Manage pages :: Living Well</title>
                </Helmet>
                { this.props.showManager &&
                    <ImagesContainer toggleImageManager={this.props.toggleImageManager} requiredSize={ this.props.requiredSize } selectImage={ this.props.selectImage } image={ this.props.image } />
                }
                <h1>{pageTitle}</h1>

                <Error error={this.props.error} />

                <form className='news-article'>
                    <div className="input text">
                        <label htmlFor="title">Title</label>
                        <input type="text" id="title" value={title}   onChange={(e) => this.props.setTitle(e.target.value)} />
                        <ValidationError errors={this.props.valErrors} fieldName="title" />
                    </div>
                    <div className="input text">
                        <label htmlFor="subtitle">Sub-title</label>
                        <input type="text" id="subtitle" value={subTitle} onChange={(e) => this.props.setSubTitle(e.target.value)} />
                        <ValidationError errors={this.props.valErrors} fieldName="subtitle" />
                    </div>
                    <div className="input text">
                        <label htmlFor="intro">Page intro</label>
                        <input type="text" id="intro" value={intro} onChange={(e) => this.props.setIntro(e.target.value)} />
                        <ValidationError errors={this.props.valErrors} fieldName="intro" />
                    </div>

                    <ImageInput 
                        size="head"
                        imageId={this.props.imageId}
                        toggleImageManager={this.props.toggleImageManager}
                        label="Header image"
                        clearFunc={this.props.clearImage}
                    />

                    <div className='greyed-sxn'>
                        <h6>Behind the scenes</h6>

                        <div className="input text">
                            <label htmlFor="slug">Slug</label>
                            <input type="text" id="slug" value={slug} onChange={(e) => this.props.setSlug(e.target.value)} />
                            <ValidationError errors={this.props.valErrors} fieldName="slug" />
                        </div>

                        <div className="input text">
                            <label htmlFor="metaDesc">Meta description</label>
                            <input type="text" id="metaDesc" value={this.props.metaDesc} onChange={(e) => this.props.setMetaDesc(e.target.value)} />
                            <ValidationError errors={this.props.valErrors} fieldName="slug" />
                        </div>

                        <div className="input text">
                            <label htmlFor="metaKw">Meta keywords</label>
                            <input type="text" id="metaKw" value={this.props.metaKw} onChange={(e) => this.props.setMetaKw(e.target.value)} />
                            <ValidationError errors={this.props.valErrors} fieldName="slug" />
                        </div>

                        <div className="input select">
                            <label htmlFor="select-template">Page template</label>
                            <select id="select-template" onChange={(e) => this.props.setTemplate(e.target.value)} value={template}>
                                <option key={'opt-default'} value={-1}  readOnly={true} disabled>Select Template</option>
                                {templateOptions.map((template, j) =>
                                    <option key={'opt-' + j} value={template.value}>{template.text}</option>
                                )}
                            </select>
                            <ValidationError errors={this.props.valErrors} fieldName="slug" />
                        </div>
                    </div>

                    <Checkbox label='Page is published' id='isPublished' checked={isPublished} handleChange={(e) => this.props.updatePublished("", e)} />

                    <button className='icon-edit-btn' onClick={(e) => this.props.updatePage(e)}>{this.props.isSaving ? savingBtnText : saveBtnText}</button>
                </form>
            </div>
        )

    }
}

export default Page;