import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

import ExtractContent from '../../../utilities/extract-content';
import EditImgContainer from '../../../containers/admin/blocks/edit-img-container';
import EditFileContainer from '../../../containers/admin/blocks/edit-file-container';

function LeftImage(props) {
    let content = ExtractContent.block(props.block, "draft");

    const [type, setType] = useState(typeof content["side-type"] !== "undefined" ? content["side-type"] : "");

    const updateType = (e) => {
        setType(e.target.value);
        props.updateField("side-type", e.target.value, true);
    }

    return (
        <div className="left-img">
            <div className="half-wrap edit">

                <div className="input select">
                    <label htmlFor={ "lftType" + props.block.id }>Content type</label>
                    <select className="" id={ "lftType" + props.block.id } defaultValue={content["side-type"]} onChange={(e) => updateType(e)}>
                        <option value=""></option>
                        <option value="img">Image</option>
                        <option value="video">Video</option>
                        <option value="map">Map</option>
                    </select>
                </div>

                { type === "img" &&
                    <EditImgContainer            
                        fieldName="img"
                        requiredSize="half"
                        image={content["img"]}
                        updateField={props.updateField}
                        imageId={content["img"]}
                    />
                }

                {type === "video" &&
                    <div className="input text">
                        <label htmlFor={"lftVideo" + props.block.id}>Video embed code</label>
                        <input type="text" className="" id={"lftVideo" + props.block.id} placeholder="Video embed code..." defaultValue={content.video} onInput={(e) => props.updateField("video", e.target.value)} onBlur={() => props.saveBlock()} />
                    </div>
                }

                {type === "map" &&
                    <div className="input text">
                        <label htmlFor={"lftMap" + props.block.id}>Video embed code</label>
                        <input type="text" className="" id={"lftMap" + props.block.id} placeholder="Map embed code..." defaultValue={content.map} onInput={(e) => props.updateField("map", e.target.value)} onBlur={() => props.saveBlock()} />
                    </div>
                }
            </div>

            <div className="content">
                <section className="width-6 right align-left written-content">
                    <div className="title-block">
                        <h3>
                            <input type="text" className="h1Input mtop-big" placeholder="Title..." defaultValue={content.title} onInput={(e) => props.updateField("title", e.target.value)} onBlur={() => props.saveBlock()} />
                        </h3>
                        <h4>
                            <input type="text" className="h1Input mtop-big" placeholder="Sub-title..." defaultValue={content.subtitle} onInput={(e) => props.updateField("subtitle", e.target.value)} onBlur={() => props.saveBlock()} />
                        </h4>
                    </div>
                    
                    <CKEditor
                        editor={InlineEditor}
                        data={content.content ? content.content : "Enter content..."}
                        config={{
                            toolbar: ['heading', '|', 'Bold', 'Italic', 'Link', "Unlink", "|", 'NumberedList', 'BulletedList', 'Blockquote', '|', 'undo', 'redo'],
                            heading: {
                                options: [
                                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                                ]
                            }
                        }}
                        onBlur={(event, editor) => {
                            const data = editor.getData();
                            props.updateField('content', data, true)
                        }}
                    />

                    <EditFileContainer
                        fieldName="files"
                        files={typeof content["files"] !== "undefined" ? content["files"] : []}
                        updateField={props.updateField}
                    />
                </section>
            </div>
        </div>
    );
}

export default LeftImage;
