// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import Wording from '../../../components/admin/wording/wording';


const WordingContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies(['csirt']);

    const [wording,setWording] = useState([]);
    const [loading, toggleIsLoading] = useState(true)
    const [isSaving,toggleIsSaving] = useState(false)
    const [newWords, setNewWords] = useState({wordName: "", words: ""});
    const [showCreate, setShowCreate] = useState(false);

    const navigate = useNavigate();

    let loginUrl = "/admin";

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadWording= () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['lwb'] == "undefined" || authCookie['lwb'] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the article you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/wording', {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            setWording(data.detail);
            toggleIsLoading(false)
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });
    }

    // Update word 
    const updateWord = (wordName, words) => {
        let newWording = [...wording];
        let wordIndex = newWording.findIndex(word => word.wordName === wordName);
        newWording[wordIndex].words = words;
        setWording(newWording);
    }

    // Save the wording
    const saveWording = (e, wordName, isCreate) => {
        e.preventDefault();
        toggleIsSaving(wordName);

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['lwb'] == "undefined" || authCookie['lwb'] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the article you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Work out the data to save
        var word;
        if (!isCreate) {
            let wordIndex = wording.findIndex(word => word.wordName === wordName);
            word = wording[wordIndex];
            console.log("EDITinG " + wordName);
        } else {
            word = newWords;
            console.log("CREATING " + wordName);
            console.log(word);
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/wording', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(word)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            toggleIsSaving(false);
            if (data.rslt === "success") {
                setError({ msg: "The wording has been saved successfully.", style: "grn" });
                setShowCreate(false);
                loadWording();
                return;
            }

            if (isCreate) {
                setError({ msg: "Sorry but there was an error creating the wording. Please try again.", style: "red" });
            } else {
                setError({ msg: "Sorry but there was an error updating the wording. Please try again.", style: "red" });
            }

        })
        .catch(function (error) {
            toggleIsSaving(false);
            setError({ msg: "Sorry but there was an error updating the wording. Please try again.", style: "red" });
        });

    }

    const updateNewWords = (key, value) => {
        var updatedNewWords = {...newWords}
        updatedNewWords[key] = value;
        setNewWords(updatedNewWords);
    }     

    const toggleShowCreate = (e) => {
        e.preventDefault();
        setShowCreate(!showCreate);
    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        loadWording();        
    }, []);
    /* eslint-enable */

    return (
        <Wording 
            loading={loading} 
            wording={wording} 
            updateWord={ updateWord } 
            saveWording={ saveWording } 
            error={error} 
            isSaving={isSaving} 
            newWords={newWords}
            setNewWords={updateNewWords}
            showCreate={showCreate}
            toggleShowCreate={toggleShowCreate}
            {...props} 
        />
    );
}

export default WordingContainer;