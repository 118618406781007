import React, { useState, useEffect } from 'react';

import Fetching from './../../utilities/fetching';
import Menu from '../../components/common/menu';


function MenusContainer(props) {

    // Set up the state to track status 
    const [menuPages, setMenuPages] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    // Load up the article content
    const loadMenu = () => {

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/public/menu/' + props.menuSlug, {
            method: 'get',
            headers: reqHeaders
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                // Append the details to the array, don't just add them
                if (props.menuSlug === "main") {
                    sortChildren(data.detail.menuPages);
                } else {
                    setMenuPages(data.detail.menuPages);
                }

            } else {
                setError(true);
            }
            setLoading(false)

        })
        .catch(function (error) {
            setLoading(false);
            // console.log('Request failed', error);
        });

    }

    // Function to add child pages into their parent sections 
    const sortChildren = (pages) => {
        var newPages = [];
        pages.forEach((page) => {
            if (page.parentId === 0) {
                newPages.push(page);
            } else {
                var parent = newPages.find((p) => p.pageId === page.parentId);
                if (typeof parent !== "undefined") {
                    if (typeof parent.children === "undefined") {
                        parent.children = [];
                    }
                    parent.children.push(page);
                } 
            }
        });
        setMenuPages(newPages);
    }

    // Trigger the load on page load
    /* eslint-disable */
    useEffect(() => {
        loadMenu();
    }, []);
    /* eslint-enable */

    return (
        <Menu
            loading={loading}
            menuPages={menuPages}
            error={error}
        />
    );
}

export default MenusContainer;
