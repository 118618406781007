// Core react and plugins
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Assets
import CloseIcon from './../../../assets/img/lw/cross-dark.svg';

// Component class itself
class Lightbox extends Component {

    render() {

        if ( !this.props.lightbox ) {
            return "";
        }

        return (

            <div className="cover">
                <div className={ typeof this.props.lightbox.isTiny != "undefined" && this.props.lightbox.isTiny ? "lb-content tiny" : "lb-content"}>
                    
                    <Link to="#close" onClick={(e) => this.props.closeFunc(e)} className="lb-close"></Link>
                    <h6>
                        { this.props.lightbox.title }
                    </h6>

                    { this.props.lightbox.content }
                </div>
            </div>

        );

    }
}

export default Lightbox;