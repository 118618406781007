import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import MenusContainer from '../../containers/pages/menus-container';
import LwLogo from './../../assets/img/lw/livingwell-logo.svg'
import Tiles from './tiles';

function HeaderSlides(props) {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }
// TODO: Open sub-menu on mobile click
    return (
        <section className="leader with-slides">
            <header className={menuOpen ? "mobile-open" : ""}>
                <div className="logo left">
                    <a href="/">
                        <img src={ LwLogo } alt="Living Well logo" />
                    </a>
                </div>
                <nav className={ menuOpen ? "main centred open" : "main centred"}>
                    <button className="mobile-menu menu-toggle" onClick={(e) => toggleMenu(e)}>Menu</button>
                    <MenusContainer menuSlug="main" setMenuOpen={setMenuOpen} />
                </nav>
            </header>

            <section className="content slideshow">
                <div className="width-4 description">
                    { props.slides.map( (slide, k) =>
                        <div key={"desc-" + k} className={ props.current && slide.id === props.current.id ? "desc-content active" : "desc-content"}>
                            <h2>{ slide.title }</h2>
                            { slide.intro !== "" &&
                                <p>{ slide.intro }</p>
                            }
                            { slide.link !== "" && slide.linkText !== "" &&
                                <Link to={ slide.link } className="pseudo-btn">{ slide.linkText }</Link>
                            }
                        </div>
                    )}

                    <ul className="dots">
                        { props.slides.map( (slide, i) => 
                            <Fragment key={'dot-' + i}>
                                { props.current && slide.id === props.current.id ? (
                                    <li className="current"><button onClick={() => props.moveSlide(i)}></button></li>
                                ) : (
                                    <li><button onClick={() => props.moveSlide(i)}></button></li>
                                )}
                            </Fragment>
                        )}
                    </ul>
                </div>

                <div className="width-8 imgs">
                    {props.slides.map((slide, j) => 
                        <div key={"img-" + j} className={(props.loadedFirstImg && props.current && slide.id === props.current.id) ? "ss-img active" : "ss-img"}>
                            <img src={slide.imgUrl } alt={slide.title} onLoad={ () => { if (j === 0) props.setLoadedFirstImg(true) }} />
                        </div>
                    )}
                </div>
            </section>

            <Tiles no={4} size="big" direction="down"/>
        </section>
    );
}

export default HeaderSlides;
