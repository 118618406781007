// Core react and plugins
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import DraggablePageContainer from './../../../containers/admin/menus/draggable-page-container';

// Components
import Error from './../common/forms/error';
import Loader from './../common/loader';


// Component class itself
class Menu extends Component {

    componentDidMount() {
        document.title = 'Manage menus - Living Well';
    }

    render() {

        const {
            menuPages,menuPageOptions,movePage,savePageReorder
        } = this.props;

        if (this.props.loading) {
            return <Loader />;
        }
        let saveBtnText= 'Save menu';
        let savingBtnText = 'Saving menu...';
        let pageTitle = 'Update menu';


        return (

            <Fragment>
                <div className="width-3 sidebar">
                    <nav>
                        <ul>
                            <li><Link to="/admin/pages">Pages</Link></li>
                            <li><Link to="/admin/menus">Menus</Link></li>
                            <li><Link to="/admin/slideshow">Homepage slideshow</Link></li>
                        </ul>
                    </nav>
                </div>

                <div className="width-9">
                    <h2 className="underlined">{pageTitle}</h2>

                    <p>Organise the pages in this menu by dragging them into the order you would like in the &quot;Menu&quot; section.</p>

                    <Error error={this.props.error} />

                    <div className="float-wrap">
                        <div className="menu-sorter">
                            <h3>Menu</h3>
                            <div className='dropzone menu critical'>
                                {
                                    menuPages.map((page,i) => {
                                        return <DraggablePageContainer id={page.id} index={i} key={'selected-'+page.id} page={page} isInMenuList={true} movePage={movePage} savePageReorder={savePageReorder}/>
                                    })
                                }
                            </div>
                        </div>
                        <div className="menu-sorter">
                            <h3>Other available pages</h3>
                            <div className="dropzone menu">
                                {
                                    menuPageOptions.map((page, j) => {
                                        return <DraggablePageContainer  id={page.id} index={j} key={'selected-'+page.id} page={page} isInMenuList={false} movePage={movePage} savePageReorder={savePageReorder}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{clear:'both'}}>
                        <button className='icon-edit-btn' onClick={(e) => this.props.savePageReorder(e)}>{this.props.isSaving ? savingBtnText : saveBtnText}</button>
                    </div>
                </div>
            </Fragment>
        )

    }
}

export default Menu;