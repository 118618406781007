import React, { useState, useEffect } from 'react';

function Tiles (props) {

    const [tiles, setTiles] = useState([]);
    const [ulClass, setUlClass] = useState("down big");

    // Figure out a random but contrasting selection of XX tiles to display
    const generateTiles = () => {

        // Set up the options
        var tileOpts = ["circle-halves", "circle-quarters", "cross", "dots", "down-dee", "half", "half-same", "lines", "opposites", "scales", "shine"];
        var colours = ["blue", "green", "yellow", "purple", "orange"];

        var newTiles = [];
        for (let i = 0; i < props.no; i++) {
            let tile = tileOpts[Math.floor(Math.random() * tileOpts.length)];
            tileOpts.splice(tileOpts.indexOf(tile),1);
            let colour = colours[Math.floor(Math.random() * colours.length)];
            colours.splice(colours.indexOf(colour),1);
            let inverse = Math.floor(Math.random() * 2) === 1 ? "-inverse" : "";
            newTiles.push("/img/patterns/" + colour + "/" + tile + inverse + ".png");
            if (colours.length === 0) colours = ["blue", "green", "yellow", "purple", "orange"];
        }
        setTiles(newTiles);

    }

    // Set up the list class 
    const generateClass = () => {

        var newClass = "down";
        if (props.direction !== "down") newClass = props.direction;
        if (typeof props.size != "undefined") newClass += " " + props.size;
        if (props.posn === "left") newClass += " " + props.posn;
        setUlClass(newClass);

    }

    /* eslint-disable */
    useEffect(() => {
        generateTiles();
        generateClass();
    }, [])
   /* eslint-enable */

    return (
        <ul className={ "tiles " + ulClass }>
            {tiles.map((tile, i) => 
                <li key={"tile-" + i} className="tile" style={{ backgroundImage: 'url("' + tile +'")' }}></li>
            )}
        </ul>
    );
}

export default Tiles;
