// Core react and plugins
import React, {  useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './../../components/admin/common/admin-header';
import CryptoJS from 'crypto-js';

// // State management
import { useCookies } from 'react-cookie';
// import AdminNav from './../../components/admin/common/admin-nav';

const HeaderContainer = (props) => {
    let location = useLocation();
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [rdr, setRdr] = useState(false); // eslint-disable-line
    const [hide, setHide] = useState(false);
    const [authCookie] = useCookies(['lwb']);
    const [permissions,setPermissions] = useState({})
    
    const getUserPermissions = () =>{

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['lwb'] == "undefined" || authCookie['lwb'] === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        setPermissions(reformData.permissions)
    }

    useEffect(() => {
        if (location.pathname.indexOf("practice") > -1 || location.pathname.indexOf("assessment") > -1) {
            setHide(true);
        } else {
            setHide(false);
        }
        getUserPermissions()
    }, [location])

    /* eslint-disable */
    useEffect(() => {
        getUserPermissions()
    },[])
   /* eslint-enable */

    // if (typeof props.layout != "undefined" && props.layout === "admin") {
    //     return (
    //         <AdminNav permissions={ permissions } />
    //     );
    // }

    return (
        <Header 
            permissions={permissions}
            hide={ hide }
        />
    );
}

export default HeaderContainer;