// Core react and plugins
import React, { Component, Fragment } from 'react';
import { Link, Navigate } from 'react-router-dom';
import TextInput from '../../common/forms/text-input';
import Button from '../../common/forms/button';
// Assets
// import ColourLogo from './../../../assets/img/login/colour-logo.svg';

// Components
import Error from './../../common/forms/error';

// Component class itself
class ForgottenPw extends Component {

    componentDidMount() {
        document.title = 'Forgotten password :: TechAssure';
    }

    render() {

        if (this.props.rdr) {
            return <Navigate to={ this.props.rdr } />
        }

        return (

            <Fragment>
                <h1>Forgotten your password?</h1>

                <Error error={ this.props.error } />

                <form>
                    <TextInput label={'Email Address'} id={'email'} fieldName="email" handleChange={ this.props.handleUpdate} />

                    <Button handleClick={ (e) => this.props.submitFunc(e) } btnTxt={ this.props.btnTxt }/>
                </form>

                <p className="sml"><Link to="/login">Return to login</Link></p>
            </Fragment>

        );

    }
}

export default ForgottenPw;