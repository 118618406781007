import React, { Fragment } from 'react';
import Error from '../admin/common/forms/error';

function Contact(props) {

    return (
        <Fragment>
            <Error error={props.error} />
            <form className="contact">
                <div className="input text half">
                    <label htmlFor="name">First name</label>
                    <input type="text" name="name" id="name" placeholder="First name" defaultValue={ props.message.firstName } onChange={ (e) => props.updateField("firstName", e.target.value) } />
                    {typeof props.valErrors.FirstName !== "undefined" &&
                        <p className="val-error">{props.valErrors.FirstName}</p>
                    }  
                </div>
                <div className="input text half">
                    <label htmlFor="last">Last name</label>
                    <input type="text" name="last" id="last" placeholder="Last name" defaultValue={props.message.lastName} onChange={(e) => props.updateField("lastName", e.target.value)} />
                    {typeof props.valErrors.LastName !== "undefined" &&
                        <p className="val-error">{props.valErrors.LastName}</p>
                    }  
                </div>
                <div className="input text half">
                    <label htmlFor="email">Email address</label>
                    <input type="text" name="email" id="email" placeholder="Email address" defaultValue={props.message.email} onChange={(e) => props.updateField("email", e.target.value)} />
                    { typeof props.valErrors.Email !== "undefined" &&
                        <p className="val-error">{props.valErrors.Email}</p>
                    }    
                </div>
                <div className="input text half">
                    <label htmlFor="phone">Phone number</label>
                    <input type="text" name="phone" id="phone" placeholder="Phone number" defaultValue={props.message.phone} onChange={(e) => props.updateField("phone", e.target.value)} />
                    {typeof props.valErrors.Phone !== "undefined" &&
                        <p className="val-error">{props.valErrors.Phone}</p>
                    }  
                </div>
                <div className="input textarea clear-both">
                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message" placeholder="Message" className="teeny-mbtm" defaultValue={props.message.message} onChange={(e) => props.updateField("message", e.target.value)}></textarea>
                    {typeof props.valErrors.Message !== "undefined" &&
                        <p className="val-error">{props.valErrors.Message}</p>
                    }  
                </div>

                <div className="input checkbox">
                    <input name="newsletter" id="newsletter" type="checkbox" defaultChecked={props.message.receiveNewsletter} onChange={(e) => props.updateField("receiveNewsletter", e.target.checked)} />
                    <label htmlFor="newsletter">I would like to receive the Living Well newsletter</label>
                </div>

                <button onClick={ (e) => props.sendMessage(e) }>Send message</button>
            </form>
        </Fragment>
    );
}

export default Contact;
