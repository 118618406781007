function CbInput(props) {

    return (
        <div className="input checkbox">
            <input type="checkbox" id={"field-" + props.fieldName} onChange={(e) => props.updateField(props.fieldName, e.target.checked)} />
            <label htmlFor={"field-" + props.fieldName}>{ props.label }</label>
        </div>
    );
}

export default CbInput;
