
// Check the status of a fetch request
const permissionsList = {
  'blocks': {
    'crud': ['Manage', 'dev']
  }
}

const Permissions = {
  // Check whether a user has permissions to access a specific action based upon their group
  actionCheck: function (resource, action, requiredPermission) {

    // If nothing is set in the permissions list then assume it can be accessed 
    // This is the default state for actions
    if (typeof permissionsList[resource] == "undefined" || typeof permissionsList[resource][action] == "undefined") {
      return true;
    }

    // Get the entry from the permissions list 
    let perms = permissionsList[resource][action];

    if ( typeof perms[requiredPermission] != "undefined" && perms[requiredPermission]) {
      return true;
    }

    return false;
   
  }
}

export default Permissions;
