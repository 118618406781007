import Tiles from '../../common/tiles';
import ExtractContent from './../../../utilities/extract-content';
import DonateAdvert from './sub-blocks/donate';

function InfoDonate(props) {

    let content = ExtractContent.block(props.block);
    if (!content) return "";

    return (
        <section className="info">
            <Tiles no={ 6 } direction="down" size="med" posn="left" />

            <div className="content">

                <div className="half">
                    { content.title && content.title !== "" && 
                        <h1>{ content.title }</h1>
                    }
                    { content.content && content.content !== "" && 
                        <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
                    }
                </div>

                <DonateAdvert content={content} class="second-half" />
            </div>
        </section>
    );
}

export default InfoDonate;
