import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import Fetching from '../../utilities/fetching';
import Donations from '../../components/pages/donations';


function PaymentsContainer(props) {

    // Manage state fo rthe donations as a whole
    const [amount, setAmount] = useState(25);
    const [type, setType] = useState("direct-debit");
    const [recurrence, setRecurrence] = useState("regular");
    const [provider, setProvider] = useState(false);
    const [goCardlessFlowID, setGoCardlessFlowID] = useState(null)
    const [handling, setHandling] = useState(false);
    const [userDetails, setUserDetails] = useState({firstName: "", lastName: "", email: "", giftAid: false});

    let { urlAmount } = useParams();
    const navigate = useNavigate();

    // Check whether the amount is parsed through the URL 
    const loadAmount = () => {
        if (typeof urlAmount != "undefined") {
            var parsedAmount = parseInt(urlAmount);
            setAmount(parsedAmount);
        }
    }

    // Handle the update of user details 
    const updateUserDetail = (fieldName, value) => {

        var uds = {...userDetails}
        if (typeof uds[fieldName] != "undefined") uds[fieldName] = value;
        setUserDetails(uds);
        console.log(uds);

    }

    // Handle donation data storage
    // Stores the data related to the submission without the payment info which is then handled via Stripe
    const handleDonationStorage = async (provider, remoteRef, callback) => {

        if (handling) return;
        setHandling(true);

        // Set up the data 
        var saveData = { ...userDetails }
        saveData.amount = parseInt(amount * 100);
        saveData.remoteRef = remoteRef;
        saveData.provider = provider;

        // Make a backend request to trigger the donation and store the details
        fetch(process.env.REACT_APP_API_BASE + '/payments/create', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Return the result
            if (data.rslt === "success") {
                callback(true);
                setHandling(false);
                return;
            }
            callback(false);
            setHandling(false);

        })
        .catch(function (error) {
            setHandling(false);
            callback(false);
        });

    }

    const handleGoCardlessPaymentType = (type) => {
        if(goCardlessFlowID == null){
            createFlow(type);
            setProvider("gocardless")
            setType(type)
        }
      
    }

    const handleDirectDebitMandateSuccess = (apiData) => {

        if(type === 'direct-debit'){
            setupDirectDebitSubscriptionGoCardless(apiData)
        } else {
            makeSinglePaymentGoCardless(apiData)
        }
    }

    const makeSinglePaymentGoCardless = (apiData) => {

        if(apiData.status !== 'fulfilled'){
            alert('Sorry there was a problem setting up the mandate for the direct debit.  This has not been processed');
            return
        }

        let body = {
            remoteRef:goCardlessFlowID,
            amount:parseFloat(amount) * 100,
            provider:'gocardless',
            firstName:apiData.resources.customer.given_name,
            lastName:apiData.resources.customer.family_name,
            email:apiData.resources.customer.email,
            address1: apiData.resources.customer_billing_detail.address_line1,
            address2: apiData.resources.customer_billing_detail.address_line2,
            address3: apiData.resources.customer_billing_detail.city,
            postcode: apiData.resources.customer_billing_detail.postal_code,
            country: apiData.resources.customer_billing_detail.country_code,
            giftAid:userDetails.giftAid,
            apiData:JSON.stringify(apiData)
        }
         // Make a backend request to trigger the donation and store the details
         fetch(process.env.REACT_APP_API_BASE + '/payments/gocardless/payment', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body:JSON.stringify(body)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Return the result
            if (data.rslt === "success") {
                console.log("REDIRECTING TO: /gift-aid?gc=payment-success&payment_intent=" + goCardlessFlowID);
                navigate("/gift-aid?gc=payment-success&payment_intent=" + goCardlessFlowID);
                return;
            } else {
                console.log(data)
                alert('Sorry there was an error setting up the direct debit payment. Please refresh the page and try again.')
                return
            }

        })
        .catch(function (error) {
            console.log(error)
            alert('Sorry there was an error setting up the direct debit payment. Please refresh the page and try again.')
            return
        });
    }

    const setupDirectDebitSubscriptionGoCardless = (apiData) => {

        if(apiData.status !== 'fulfilled'){
            alert('Sorry there was a problem setting up the mandate for the direct debit.  This has not been processed');
            return
        }


        if(!amount){
            alert('An amount must be set');
            return;
        }

        let body = {
            remoteRef:goCardlessFlowID,
            amount:parseFloat(amount) * 100,
            provider:'gocardless',
            firstName:apiData.resources.customer.given_name,
            lastName:apiData.resources.customer.family_name,
            email:apiData.resources.customer.email,
            address1: apiData.resources.customer_billing_detail.address_line1,
            address2: apiData.resources.customer_billing_detail.address_line2,
            address3: apiData.resources.customer_billing_detail.city,
            postcode: apiData.resources.customer_billing_detail.postal_code,
            country: apiData.resources.customer_billing_detail.country_code,
            giftAid:userDetails.giftAid,
            apiData:JSON.stringify(apiData)
        }
         // Make a backend request to trigger the donation and store the details
         fetch(process.env.REACT_APP_API_BASE + '/payments/gocardless/direct_debit', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body:JSON.stringify(body)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Return the result
            if (data.rslt === "success") {
                setGoCardlessFlowID(data.detail);
                console.log("REDIRECTING TO: /gift-aid?gc=sub-success&payment_intent=" + goCardlessFlowID);
                navigate("/gift-aid?gc=payment-success&payment_intent=" + goCardlessFlowID);
                return;
            } else {
                console.log(data)
                alert('Sorry there was a problem setting up the mandate for the direct debit. Please refresh the page and try again.');
                return
            }

        })
        .catch(function (error) {
            console.log(error)
            alert('Sorry there was a problem setting up the mandate for the direct debit. Please refresh the page and try again.');
            return
        });
    }

    // React.useEffect(() => {
       const createFlow = (type) => {

            const body = {
                type: type,
                giftAid: userDetails.giftAid
            }

            // Make a backend request to trigger the donation and store the details
            fetch(process.env.REACT_APP_API_BASE + '/payments/gocardless/mandate', {
                method: 'post',
                headers: {
                    "Content-type": "application/json; charset=utf-8"
                },
                body:JSON.stringify(body)
            })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {

                // Return the result
                if (data.rslt === "success") {
                    setGoCardlessFlowID(data.detail);
                    return;
                } else {
                    console.log(data)
                    alert('Sorry there was an error setting up the direct debit mandate')
                    return
                }

            })
            .catch(function (error) {
                console.log(error)
                alert('Sorry there was an error setting up the direct debit mandate')
                return
            });
        }

    // Update the recurrence
    const updateDonationRecurrence = (e, recurrence) => {
        e.preventDefault();
        if (!provider) {
            setRecurrence(recurrence);
        }
    }

    /* eslint-disable */
    useEffect(() => {
        loadAmount();
    }, []);
    /* eslint-enable */

    return (
        <Donations 
            setAmount={ setAmount }
            amount={ amount }
            type={type}
            provider={provider }
            recurrence={recurrence}
            setType={setType}
            setProvider={setProvider }
            updateUserDetail={updateUserDetail}
            giftAid={userDetails.giftAid}
            handleDonationStorage={handleDonationStorage}
            handleGoCardlessPaymentType={handleGoCardlessPaymentType}
            goCardlessFlowID={goCardlessFlowID}
            handleDirectDebitMandateSuccess={handleDirectDebitMandateSuccess}
            updateDonationRecurrence={updateDonationRecurrence}
        />
    );
}

export default PaymentsContainer;