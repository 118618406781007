import React, { Fragment } from 'react';
import FilesContainer from '../../../../containers/admin/files/files-container';

function EditFile(props) {

    return (
        <Fragment>
            <div className="downloads">
                <h4>File downloads</h4>
                { typeof props.addedFiles !== "undefined" && props.addedFiles !== null && props.addedFiles.length > 0 &&
                    <ul className="files-list">
                        {props.addedFiles.map((file, index) => 
                            <li key={"f-" + index}>
                                { file.Filename }
                                <button className="plain remove-file" onClick={(e) => props.removeFile(file.ID)}>Remove</button>
                            </li>
                        )}
                    </ul>
                }

                <div className="edit-file">
                    <button className={typeof props.class !== "undefined" ? props.class + " edit-img-btn" : "edit-img-btn"} onClick={(e) => props.toggleImageManager(e, props.size)}>{ "Add file" }</button>
                </div>

                {props.isOpen &&
                    <FilesContainer {...props} />
                }
            </div>
        </Fragment>
    );
}

export default EditFile;
