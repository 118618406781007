import React, {useState} from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline'; 

import ExtractContent from './../../../utilities/extract-content';
import EditableButton from '../../admin/common/forms/editable-button';
import EditImgContainer from '../../../containers/admin/blocks/edit-img-container';

function HalfResponses(props) {

    const [act1, setAct1] = useState(false);
    const [act2, setAct2] = useState(false);

    let content = ExtractContent.block(props.block, "draft");

    return (
        <section className="half-responses">
            
            <div className="content">
                <div className="width-6 response">
                    <EditImgContainer
                        fieldName="img-1"
                        requiredSize="response"
                        image={content["img-1"]}
                        updateField={props.updateField}
                        imageId={content["img-1"]}
                    />
    
                    <h3>
                        <input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-1"]} onInput={(e) => props.updateField("title-1", e.target.value)} onBlur={() => props.saveBlock()} />
                    </h3>

                    <CKEditor
                        editor={InlineEditor}
                        data={content["content-1"] ? content["content-1"] : "Enter content..."}
                        config={{
                            toolbar: ['bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                        }}
                        onBlur={(event, editor) => {
                            const data = editor.getData();
                            props.updateField('content-1', data, true)
                        }}
                    />

                    <div className="input select admin-block">
                        <label htmlFor='action-1'>Response type</label>
                        <select id='action-1' name='action-1' defaultValue={content["action-1"]} onChange={(e) => { setAct1(e.target.value); props.updateField("action-1", e.target.value, true);}}>
                            <option value=""></option>
                            <option value="newsletter">Newsletter signup</option>
                            <option value="link">Link button</option>
                        </select>
                    </div>

                    { ((act1 && act1 === "link") || (!act1 && content["action-1"] && content["action-1"] === "link")) &&
                        <EditableButton
                            btnTxt={typeof content["action-txt-1"] !== "undefined" ? content["action-txt-1"] : "Enter link text..."}
                            fieldName="action-txt-1"
                            fieldNameLnk="action-link-1"
                            btnLnk={typeof content["action-link-1"] !== "undefined" ? content["action-link-1"] : ""}
                            updateField={props.updateField}
                            saveBlock={props.saveBlock}
                        />
                    }
                </div>

                <div className="width-6 response">
                    <EditImgContainer
                        fieldName="img-2"
                        requiredSize="response"
                        image={content["img-2"]}
                        updateField={props.updateField}
                        imageId={content["img-2"]}
                    />

                    <h3>
                        <input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title-2"]} onInput={(e) => props.updateField("title-2", e.target.value)} onBlur={() => props.saveBlock()} />
                    </h3>

                    <CKEditor
                        editor={InlineEditor}
                        data={content["content-2"] ? content["content-2"] : "Enter content..."}
                        config={{
                            toolbar: ['bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                        }}
                        onBlur={(event, editor) => {
                            const data = editor.getData();
                            props.updateField('content-2', data, true)
                        }}
                    />

                    <div className="input select admin-block">
                        <label htmlFor='action-2'>Response type</label>
                        <select id='action-2' name='action-2' defaultValue={content["action-2"]} onChange={(e) => { setAct2(e.target.value); props.updateField("action-2", e.target.value, true); }}>
                            <option value=""></option>
                            <option value="newsletter">Newsletter signup</option>
                            <option value="link">Link button</option>
                        </select>
                    </div>

                    { ((act2 && act2 === "link") || (!act2 && content["action-2"] && content["action-2"] === "link")) &&
                        <EditableButton
                            btnTxt={typeof content["action-txt-2"] !== "undefined" ? content["action-txt-2"] : "Enter link text..."}
                            fieldName="action-txt-2"
                            fieldNameLnk="action-link-2"
                            btnLnk={typeof content["action-link-2"] !== "undefined" ? content["action-link-2"] : ""}
                            updateField={props.updateField}
                            saveBlock={props.saveBlock}
                        />
                    }

                </div>
            </div>
        </section>
    );
}

export default HalfResponses;
