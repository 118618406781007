// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import PageList from './../../../components/admin/pages/page-list';


const PageListContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies(['csirt']);

    const [pages,setPages] = useState([]);
    const [loading, toggleIsLoading] = useState(true)
    const [isSaving,toggleIsSaving] = useState(false)
    const [valErrors,setValErrors] = useState(false);

    const navigate = useNavigate();

    let loginUrl = "/admin";

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadPages= () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['lwb'] == "undefined" || authCookie['lwb'] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the article you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/pages', {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            setPages(data.detail);
            toggleIsLoading(false)
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });
    }

    // Delete a page
    const deletePage = ( e,pageSlug ) => {

        e.preventDefault();
        toggleIsSaving(true);
        setError(false);

        let doDelete = window.confirm('Do you wish to remove this page?');

        if(!doDelete){
            return;
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['lwb'] == "undefined" || authCookie['lwb'] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));


        if(pageSlug !== 'create'){
            pageSlug = pageSlug.replace('/','-fs-'); 
        }
   
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/pages/'+pageSlug, {
            method: 'delete',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if ( typeof data.rslt != "undefined" && data.rslt ) {
                // setError({ msg: "Your article has been successfully saved.", style: "grn" });
                flashActions.set({ msg: "The page has been successfully deleted.", style: "grn" });
                
                
                toggleIsSaving(false);
                loadPages();
                return
            } else if (data.rslt === 'error'){
                setValErrors(data.messages);
            }
            
            setError({ msg: "There was an error deleting the page. Please try again.", style: "red" });
            toggleIsSaving(false);
            return;

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page.", style: "red" });
            }
            toggleIsSaving(false);
            return;
        });

    }
  

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        loadPages();        
    }, []);
    /* eslint-enable */

    // useEffect to load up the auth check when the component mounts
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);
// 
    // let createArticlePage = (articleSlug ==='create') ? true : false;

    return (
        <PageList loading={loading} pages={pages} deletePage={deletePage} error={error} valErrors={valErrors} isSaving={isSaving} {...props} />
    );
}

export default PageListContainer;