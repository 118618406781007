import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import CryptoJS from 'crypto-js';

import Fetching from './../../utilities/fetching';
import ExtractContent from './../../utilities/extract-content';


// Import the blocks (admn and non-admin)
import InfoDonate from '../../components/blocks/public/info-donate';
import InfoDonateAdmin from '../../components/blocks/admin/info-donate'; 
import PlainDonate from '../../components/blocks/public/plain-donate';
import PlainDonateAdmin from '../../components/blocks/admin/plain-donate';
import ContactDonate from '../../components/blocks/public/contact-donate';
import ContactDonateAdmin from '../../components/blocks/admin/contact-donate';
import HalfResponses from '../../components/blocks/public/half-responses';
import HalfResponsesAdmin from '../../components/blocks/admin/half-responses';
import SocialBar from '../../components/blocks/public/social-bar';
import SocialBarAdmin from '../../components/blocks/admin/social-bar';
import LeftImage from '../../components/blocks/public/left-img';
import LeftImageAdmin from '../../components/blocks/admin/left-img';
import RightImage from '../../components/blocks/public/right-img';
import RightImageAdmin from '../../components/blocks/admin/right-img';
import ThirdResponses from '../../components/blocks/public/third-responses';
import ThirdResponsesAdmin from '../../components/blocks/admin/third-responses';
import Instagram from '../../components/blocks/public/instagram';
import InstagramAdmin from '../../components/blocks/admin/instagram';
import Video from '../../components/blocks/public/video';
import VideoAdmin from '../../components/blocks/admin/video';
import ThreeRow from '../../components/blocks/public/three-in-a-row';
import ThreeRowAdmin from '../../components/blocks/admin/three-in-a-row';
import PlainTextBlue from '../../components/blocks/public/plain-text-blue';
import PlainTextBlueAdmin from '../../components/blocks/admin/plain-text-blue';
import PlainTxt from '../../components/blocks/public/plain-txt';
import PlainTxtAdmin from '../../components/blocks/admin/plain-txt';
import ImgSide from '../../components/blocks/public/img-side';
import ImgSideAdmin from '../../components/blocks/admin/img-side';


function BlocksContainer(props) {

    // Set up the state to track status 
    const [auth, setAuth] = useState(false);
    const [blockContent, setBlockContent] = useState({});
    const [error, setError] = useState("");

    const [authCookie] = useCookies(['lwb']);
    let encSecret = process.env.REACT_APP_ENC_KEY;

    // Get the auth state from the cookies - to pass down
    const loadAuthState = () => {

        // Check for a cookie
        if (authCookie && Object.keys(authCookie).length > 0 && typeof authCookie['lwb'] != "undefined" && typeof authCookie['lwb'] !== "undefined") {

            var reformData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
            reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

            setAuth(reformData);

        }

    }

    // Handle field updates from the forms
    const updateField = (fieldName, value, triggerSave = false) => {

        // Update the state
        var newContent = { ...blockContent }
        console.log(newContent);
        newContent[fieldName] = value;
        setBlockContent(newContent);
        // If this is omething that should trigger a save, then do so
        if (triggerSave) {
            saveBlock(fieldName, value);
        }

    }

    // Handle save of the block itself on blur of the fields
    const saveBlock = (fieldName, value) => {

        // Figure out the save data 
        var saveData = {
            blockId: props.block.id,
            content: blockContent
        }

        // If this is a directly triggered (e.g. CKEditor) save then don't rely on state (it updates asynchronously)
        if (typeof fieldName != "undefined" && typeof value != "undefined") {
            saveData.content[fieldName] = value;
        }

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/blocks/1', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': auth.apiToken,
                'api-key': auth.apiKey
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (typeof data.rslt == "undefined" && data.rslt !== "success") {
                setError("Sorry but there was an error saving that change. Please try again.");
                return;
            }
            setError(false);
        })
        .catch(function (error) {
            setError("Sorry but there was an error saving that change. Please try again.");
            console.log('Request failed', error);
        });

    }

    // Get the menus for the header 
    const loadContent = () => {

        // CHECK THE AUTH STATE 
        var canEdit = false;
        if (authCookie && Object.keys(authCookie).length > 0 && typeof authCookie['lwb'] != "undefined" && typeof authCookie['lwb'] !== "undefined") {
            var authData = CryptoJS.AES.decrypt(authCookie['lwb'], encSecret)
            authData = JSON.parse(authData.toString(CryptoJS.enc.Utf8));
            if (authData.permissions.Manage) {
                canEdit = true;
            }
        }

        var content;
        if (canEdit) {
            content = ExtractContent.block(props.block, "draft");
        } else {
            content = ExtractContent.block(props.block);
        }
        setBlockContent(content);

    }

    // Trigger the load on page load
    /* eslint-disable */
    useEffect(() => {
        loadContent();
        loadAuthState();
    }, []);
    /* eslint-enable */

    // 
    if (auth && auth.permissions && auth.permissions.Manage) {

        // Set up the block options 
        const blockOpts = {
            "plain-donate": <PlainDonateAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "contact-donate": <ContactDonateAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "half-responses": <HalfResponsesAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "social-bar": <SocialBarAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "left-img": <LeftImageAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "right-img": <RightImageAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "instagram": <InstagramAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "info-donate": <InfoDonateAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "video": <VideoAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "plain-text": <PlainTxtAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "plain-text-blue": <PlainTextBlueAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "three-in-a-row": <ThreeRowAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "img-side": <ImgSideAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "third-responses": <ThirdResponsesAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />
        }

        if ( typeof blockOpts[props.block.blockStyle] !== "undefined") {
            return blockOpts[props.block.blockStyle]
        }

    }

    // Set up the block options 
    const blockOptsPublic = {
        "plain-donate": <PlainDonate block={props.block} />,
        "contact-donate": <ContactDonate block={props.block} />,
        "half-responses": <HalfResponses block={props.block} />,
        "social-bar": <SocialBar block={props.block} />,
        "left-img": <LeftImage block={props.block} />,
        "right-img": <RightImage block={props.block} />,
        "instagram": <Instagram block={props.block} />,
        "info-donate": <InfoDonate block={props.block} />,
        "video": <Video block={props.block} />,
        "three-in-a-row": <ThreeRow block={props.block} />,
        "plain-text": <PlainTxt block={props.block} />,
        "plain-text-blue": <PlainTextBlue block={props.block} />,
        "img-side": <ImgSide block={props.block} />,
        "third-responses": <ThirdResponses block={props.block} />
    }

    if (typeof blockOptsPublic[props.block.blockStyle] !== "undefined") {
        return blockOptsPublic[props.block.blockStyle]
    }

    return "";
}

export default BlocksContainer;
