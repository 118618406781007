// Core react and plugins
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import Error from './../common/forms/error';
import Loader from './../common/loader';
import ReportsSidebar from '../common/reports-sidebar';

// Component class itself
const Donations = (props) => {

    if (props.loading) {
        return <Loader />;
    }

    const ucFirst = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (

        <Fragment>
            { props.detail && 
                <div className="cover">
                    <div className="hover-box large">
                        <Link to="#close" className="close-lnk" onClick={ (e) => props.closeDetail(e) }>Close</Link>
                        <h2>Donation detail</h2>

                        <div className="half">
                            <h4>Donor information</h4>
                            <ul className="detail">
                                <li><span>First name:</span> {props.detail.FirstName}</li>
                                <li><span>Last name:</span> {props.detail.LastName}</li>
                                <li><span>Email address:</span> {props.detail.Email}</li>
                                <li><span>Address 1:</span> {props.detail.Address1}</li>
                                <li><span>Address 2:</span> {props.detail.Address2}</li>
                                <li><span>Town / City:</span> {props.detail.Address3}</li>
                                <li><span>Postcode:</span> {props.detail.Postcode}</li>
                                <li><span>Country:</span> { props.detail.Country }</li>
                            </ul>
                        </div>

                        <div className="half">
                            <h4>Donation information</h4>
                            <ul className="detail">
                                <li><span>Provider:</span> { ucFirst(props.detail.Provider)}</li>
                                <li><span>Status:</span> {ucFirst(props.detail.Status)}</li>
                                <li><span>Amount:</span> &pound;{props.detail.Amount / 100}</li>
                                { props.detail.Provider === "stripe" ? (
                                    <Fragment>
                                        <li><span>Fees:</span> &pound;{props.detail.Fees / 100}</li>
                                        <li><span>Net amount:</span> &pound;{props.detail.NetAmount / 100}</li>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <li><span>Monthly donation:</span> {props.detail.SubscriptionID > 0 ? "Yes" : "No"}</li>
                                    </Fragment>
                                )}
                                <li><span>Can Gift Aid:</span> {props.detail.GiftAid ? "Yes" : "No"}</li>
                            </ul>
                        </div>

                        { props.detail.Message &&
                            <div className="clear msg">
                                <h4>Donation message</h4>
                                <div className="detail">
                                    <p dangerouslySetInnerHTML={{ __html: props.detail.Message.replace(/\n/g, "<br />") }}></p>
                                </div>
                            </div>
                        }

                        <div className="clear">
                            <h4>Transaction information</h4>
                            <ul className="detail">
                                { props.detail.Transactions.map( (txn, i) => 
                                    <li key={"txn-" + i}>
                                        <span>{ moment(txn.CreatedAt).format("HH:mm, DD/MM/YYYY") }:</span> 
                                        { ucFirst(txn.Status) }
                                    </li>
                                )}
                            </ul>
                        </div>

                    </div>
                </div>
            }


            <ReportsSidebar />

            <div className="width-9 scroll-table" onScroll={props.handleScroll}>
                <Link to="#export" className="btn right" onClick={ (e) => props.exportDonations(e) }>Export CSV</Link>
                <h1>Manage Donations</h1>

                <Error error={ props.error } />

                <div className='account-forms'>
                    <table className="network">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Amount</th>
                                <th>Net</th>
                                <th>Can gift aid</th>
                                <th>Created</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!props.donations || props.donations.length === 0 ? (
                                <tr>
                                    <td colSpan="8"><em>Nothing to display</em></td>
                                </tr>
                            ) : (
                                <Fragment>
                                    {props.donations.map((donation, i) => 
                                        <Fragment key={'su-' + i}>
                                            <tr className={ donation.Status !== "refunded" && donation.Status !== "cancelled" && (donation.Amount - donation.Refunded) > 0 ? "" : "faded" }>
                                                <td className="status">
                                                    <span className={ donation.Status } title={ ucFirst(donation.Provider) + ": " + ucFirst(donation.Status) }>
                                                        {donation.Provider === "gocardless" ? "G" : "S"}
                                                    </span>
                                                </td>
                                                <td className="">
                                                    {donation.FirstName }
                                                </td>
                                                <td className="">
                                                    {donation.LastName}
                                                </td>
                                                <td className="">
                                                    &pound;{donation.Amount / 100}
                                                </td>
                                                <td className="">
                                                    { donation.Provider === "stripe" ? (
                                                        <Fragment>&pound;{donation.NetAmount / 100}</Fragment>
                                                    ) : (
                                                        <Fragment>-</Fragment>
                                                    )}
                                                </td>
                                                <td className="">
                                                    {donation.GiftAid ? "Yes" : "No"}
                                                </td>
                                                <td className="">
                                                    {moment(donation.CreatedAt ).format('DD/MM/YYYY') }
                                                </td>
                                                <td className="">
                                                    <button className="action-btn" onClick={(e) => props.launchDetail(donation.id)}>Detail</button>
                                                    {donation.Status !== "refunded" && donation.Status !== "cancelled" && (donation.Amount - donation.Refunded) > 0 &&
                                                        <Fragment>
                                                            { donation.Status === "pending_submission" && donation.Provider === "gocardless" ? (
                                                                <button className="action-btn" onClick={(e) => props.cancel(donation.id)}>Cancel</button>                                                    
                                                            ) : (
                                                                <button className="action-btn" onClick={(e) => props.refund(donation.id)}>Refund</button>                                                    
                                                            )}
                                                        </Fragment>    
                                                    }
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

        </Fragment>

    );

}

export default Donations;