import React from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline'; 

import ExtractContent from '../../../utilities/extract-content';
import EditImgContainer from '../../../containers/admin/blocks/edit-img-container';

function ThreeRow(props) {

    let content = ExtractContent.block(props.block, "draft");

    return (
        <section className="three-row">
            
            <div className="content narrow">

                <h2>
                    <input type="text" className="white mtop-big" placeholder="Title..." defaultValue={content["title"]} onInput={(e) => props.updateField("title", e.target.value)} onBlur={() => props.saveBlock()} />
                </h2>

                <div className="side-sml lft-side">
                    <EditImgContainer
                        fieldName="img-1"
                        requiredSize="small"
                        image={content["img-1"]}
                        updateField={props.updateField}
                        imageId={content["img-1"]}
                    />

                    <div className="side-content">
                        <h3>
                            <input type="text" className="" placeholder="Sub-title..." defaultValue={content["title-1"]} onInput={(e) => props.updateField("title-1", e.target.value)} onBlur={() => props.saveBlock()} />
                        </h3>

                        <CKEditor
                            editor={InlineEditor}
                            data={content["content-1"] ? content["content-1"] : "Enter content..."}
                            config={{
                                toolbar: ['heading', '|', 'Bold', 'Italic', 'Link', "Unlink", "|", 'NumberedList', 'BulletedList', 'Blockquote', '|', 'undo', 'redo'],
                                heading: {
                                    options: [
                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                                    ]
                                }
                            }}
                            onBlur={(event, editor) => {
                                const data = editor.getData();
                                props.updateField('content-1', data, true)
                            }}
                        />

                    </div>
                </div>

                <div className="side-sml rght-side">
                    <EditImgContainer
                        fieldName="img-2"
                        requiredSize="small"
                        image={content["img-2"]}
                        updateField={props.updateField}
                        imageId={content["img-2"]}
                    />

                    <div className="side-content">
                        <h3>
                            <input type="text" className="" placeholder="Sub-title..." defaultValue={content["title-2"]} onInput={(e) => props.updateField("title-2", e.target.value)} onBlur={() => props.saveBlock()} />
                        </h3>

                        <CKEditor
                            editor={InlineEditor}
                            data={content["content-2"] ? content["content-2"] : "Enter content..."}
                            config={{
                                toolbar: ['heading', '|', 'Bold', 'Italic', 'Link', "Unlink", "|", 'NumberedList', 'BulletedList', 'Blockquote', '|', 'undo', 'redo'],
                                heading: {
                                    options: [
                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                                    ]
                                }
                            }}
                            onBlur={(event, editor) => {
                                const data = editor.getData();
                                props.updateField('content-2', data, true)
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ThreeRow;
