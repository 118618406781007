import React, { useState } from 'react';
import EditFile from '../../../components/admin/common/forms/edit-file';

function EditFileContainer(props) {

    const [files, setFiles] = useState(props.files);
    const [isOpen, setIsOpen] = useState(false);

    const toggleImageManager = (e, size) => {
        if (e) e.preventDefault();
        setIsOpen(!isOpen);
    }

    const addFile = (file) => {
        var newFiles = [...files];
        newFiles.push(file);
        if (typeof props.updateField !== "undefined") {
            props.updateField(props.fieldName, newFiles, true);
        }
        setFiles(newFiles);
        toggleImageManager(false);
    }

    const removeFile = (fileId) => {
        var newFiles = files.filter(file => file.ID !== fileId);
        if (typeof props.updateField !== "undefined") {
            props.updateField(props.fieldName, newFiles, true);
        }
        setFiles(newFiles);
    }

    return (
        <EditFile
            isOpen={isOpen}
            toggleImageManager={toggleImageManager}
            addFile={addFile}
            addedFiles={files}
            removeFile={removeFile}
            {...props}
        />
    );
}

export default EditFileContainer;
