import { Fragment, useState } from 'react';
import { NavLink } from "react-router-dom";

function Menu(props) {

    const [openChild, setOpenChild] = useState(false);

    const handleLink = (e, id, isParent) => {

        let width = getWidth();

        // If we are on mobile then do more then just redirect 
        if (width < 800) {

            if (isParent && id !== openChild) {
                // If this is a parent link then prevent the default action
                e.preventDefault();
                // let lnk = e.target.href;

                setOpenChild(id);
                // if (openChild === id) {
                //     setOpenChild(0);
                // } else {
                //     setOpenChild(id);
                // }
            } else {
                // Close the menu
                props.setMenuOpen(false);
            }
        }
    }

    const getWidth = () => {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }


    if (props.loading || !props.menuPages) {
        return <Fragment></Fragment>;
    }

    return (
        <ul>
            {props.menuPages.map((page, i) =>
                <li key={ "item-" + i } className={ typeof page.children !== "undefined" && page.children.length > 0 ? "has-children" : "" }>
                    {page.page.slug === "/lw-rota" ? (
                        <a className={page.page.linkClass} href="https://rota.livingwell.life" target="_blank" rel="noreferrer">{page.page.title}</a>
                    ) : (
                        <NavLink className={({ isActive }) => { return isActive ? page.page.linkClass + " active" : page.page.linkClass }} to={page.page.slug === "/home" ? "/" : "/p" + page.page.slug} onClick={(e) => handleLink(e, page.id, (typeof page.children !== "undefined" && page.children.length > 0) ? true : false)}>{page.page.title}</NavLink>
                    )}
                    {typeof page.children !== "undefined" && page.children.length > 0 &&
                        <ul className={ openChild === page.id ? "mob-open" : "" }>
                            {page.children.map((child, i) =>
                                <li key={ "child-" + i }>
                                    {child.page.slug.indexOf("http") > -1 ? (
                                        <a className={ child.page.linkClass } href={child.page.slug} target="_blank" rel="noreferrer">{child.page.title}</a>
                                    ) : (
                                        <NavLink className = { ({ isActive }) => { return isActive ? child.page.linkClass + " active" : child.page.linkClass }} to={"/p" + child.page.slug} onClick={(e) => handleLink(e, page.id, false)}>{child.page.title}</NavLink>
                                    )}
                                </li>
                            )}
                        </ul>
                    }
                </li>
            )}
            <li className="mob-display">
                <NavLink to="/p/donate" className="mobile donate" onClick={(e) => handleLink(e, 0, false)}>Donate</NavLink>
            </li>
        </ul>
    );
}

export default Menu;
