import React from 'react';
import Button from '../../common/forms/button';
import TextInput from '../../common/forms/text-input';
import { Navigate } from 'react-router-dom';
import ValidationError from '../../common/forms/validation-error';
import Error from '../../common/forms/error';
import Password from '../../common/forms/password';

function ChangeDetails(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    return (
        <div>
            <section className='width-6 centred'>

                <h1>Edit my Details</h1>

                <Error error={props.error}/>
                
                <form>
                    <TextInput label='First Name' id='firstName' value={props.settings.firstName} fieldName="firstName" handleChange={props.handleUpdate }/>
                    <TextInput label='Last Name' id='lastName' value={props.settings.lastName} fieldName="lastName" handleChange={props.handleUpdate }/>
                    <TextInput label='Email' id='email' value={props.settings.email} fieldName="email" handleChange={props.handleUpdate }/>
                    <ValidationError errors={props.valErrors} fieldName='email'/>
                    <TextInput label='Phone' id='phone' value={props.settings.phone} fieldName="phone" handleChange={props.handleUpdate }/>

                    <hr className='password-divider'/>
                    <Password label='Password' id='password' value={props.settings.password} fieldName="password" handleChange={ props.handleUpdate }/>
                    <ValidationError errors={props.valErrors} fieldName='password'/>
                    <Button btnTxt={props.btnTxt} id='save_btn' handleClick={props.saveUser}/>
                </form>
            </section>
  
        </div>
    )
}

export default ChangeDetails