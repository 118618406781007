import React, { Component } from 'react';
import { Routes, Route, Navigate  } from 'react-router-dom'
import LoginContainer from '../containers/admin/users/auth/login-container';
import LogoutContainer from '../containers/admin/users/auth/logout-container';
import ForgottenPwContainer from '../containers/admin/users/auth/forgotten-pw-container';
import SetPwContainer from '../containers/admin/users/auth/set-pw-container';

import LwLogo from './../assets/img/lw/livingwell-logo.svg';

class Routing extends Component {
 
    render () {
        if (this.props.rdr) {
            return <Navigate  to={ this.props.rdr } />
        }

        return (
            <div className="login">
                <div className='logo-sxn'>
                    <img src={LwLogo} alt="Living Well Bromley logo" />
                </div>
                <div className="core">
                    <div className="login-content">
                        <Routes>
                            <Route path='forgotten-password' element={ <ForgottenPwContainer/> } />
                            <Route path='set-password/:resetToken/:resetKey/:setType' element={ <SetPwContainer/> } />
                            <Route path='set-password/:resetToken/:resetKey' element={ <SetPwContainer/> } />
                            <Route path='logout' element={ <LogoutContainer/> } />
                            <Route index element={<LoginContainer />} />
                            <Route path="*" element={<LoginContainer/>} />
                        </Routes>
                    </div>
                </div>
            </div>
        );
    }
}

export default Routing;
