import Error from "../admin/common/forms/error";

function NewsletterSignup(props) {

    return (
        <form className="inline-email">
            <input type="email" placeholder="Enter your email address" onChange={ (e) => props.setEmail(e.target.value) } />
            <button onClick={ (e) => props.signup(e) }>{ props.btnTxt }</button>

            <Error { ...props } />
        </form>                        
    );
}

export default NewsletterSignup;
