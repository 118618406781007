import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Fetching from '../../utilities/fetching';
import StripeForm from "./../../components/common/payments/stripe-form";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function StripeContainer(props) {

    const [intent, setIntent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    // Handle the request for payment intent
    const getIntent = () => {

        console.log(loading);
        if (loading || intent) return;
        setLoading(true);

        // Assemble the request data
        let reqData = {
            amount: parseInt(props.amount)
        }

        fetch(process.env.REACT_APP_API_BASE + '/payments/stripe/intent', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(reqData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                // Append the details to the array, don't just add them
                if (!intent) setIntent(data.detail);

            } else {
                setError({ msg: "Sorry but there was an error setting up your donation. Plrease refresh the page and try again.", style: "error" });
            }
            setLoading(false)

        })
        .catch(function (error) {
            setLoading(false)
            setError({ msg: "Sorry but there was an error setting up your donation. Plrease refresh the page and try again.", style: "error" });
            // console.log('Request failed', error);
        });

    }

    /* eslint-disable */
    useEffect(() => {
        console.log("FIRING intent get");
        getIntent();
    }, []);
    /* eslint-enable */

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret: intent,
        appearance
    };

    return (
        <div className="stripe-form">
            {intent ? (
                <Elements options={options} stripe={stripePromise}>
                    <StripeForm intent={ intent } {...props} />
                </Elements>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default StripeContainer;