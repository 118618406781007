// Core react and plugins
import React, { Fragment } from 'react';

// Component class itself
const Wording = (props) => {


    return (
        <Fragment>                
            { props.wording !== "" ? (
                <span dangerouslySetInnerHTML={{ __html: props.wording }}></span>
            ) : (
                <span dangerouslySetInnerHTML={{ __html: props.default }}></span>
            )}
        </Fragment>
    );

}

export default Wording;