// Core react and plugins
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';


// Component class itself
function InstagramFeed(props) {

    if (!props.imgs || props.imgs.length === 0) {
        if (props.isAdmin) {
            return (
                <Fragment>
                    <p className="mbtm">There are no images to display. <Link to="/admin/instagram">Check your configuration</Link>.</p>
                </Fragment>
            )
        } 
        return "";
    }

    return (
        <Fragment>
            <div className="slider-container">
                <button className={ props.displayHandles ? "prev" : "prev off"} onClick={() => { props.updateLftPosn(-1) }}></button>
                <button className={props.displayHandles ? "nxt" : "nxt off"} onClick={() => { props.updateLftPosn(1) }}></button>
                <div id="slideWrap">
                    <ul className="slider" style={{ left: props.lftPosn }}>
                        { props.imgs.map ( (img, i) => 
                            <li key={"inst-" + i}><a style={{ backgroundImage: (img.small_url !== "") ? 'url(' + img.small_url + ')' : 'url(' + img.remote_url + ')' }} href={ img.link_url } target="_blank" rel="noreferrer">Instagram image</a></li>
                        )}
                    </ul>
                </div>
            </div>
        </Fragment>
    )

}

export default InstagramFeed;