import React from 'react';

import ExtractContent from '../../../utilities/extract-content';
import DisplayImageContainer from '../../../containers/images/display-image-container';

function ThreeRow(props) {

    let content = ExtractContent.block(props.block);

    return (
        <section className="three-row">
            
            <div className="content narrow">
                {typeof content["title"] !== "undefined" && content["title"] !== "" &&
                    <h2>{content["title"]}</h2>
                }

                {(content["title-1"] !== "" || content["img-1"] !== "" || content["content-1"] !== "") &&
                    <div className="a-third">
                        { typeof content["img-1"] !== "undefined" && content["img-1"] !== "" && 
                            <DisplayImageContainer
                                imageId={content["img-1"]}
                                size="small"
                                class="sxn-img"
                                isBgImage={true}
                            />
                        }
        
                        { content["title-1"] !== "" &&
                            <h3>{content["title-1"]}</h3>
                        }
                        {content["content-1"] !== "" &&
                            <div dangerouslySetInnerHTML={{__html: content["content-1"]}}></div>
                        }
                    </div>
                }

                {(content["title-2"] !== "" || content["img-2"] !== "" || content["content-2"] !== "") &&
                    <div className="a-third">
                        {typeof content["img-2"] !== "undefined" && content["img-2"] !== "" &&
                            <DisplayImageContainer
                                imageId={content["img-2"]}
                                size="small"
                                class="sxn-img"
                                isBgImage={true}
                            />
                        }

                        {content["title-2"] !== "" &&
                            <h3>{content["title-2"]}</h3>
                        }
                        {content["content-2"] !== "" &&
                            <div dangerouslySetInnerHTML={{ __html: content["content-2"] }}></div>
                        }
                    </div>
                }

                {(content["title-3"] !== "" || content["img-3"] !== "" || content["content-3"] !== "") &&
                    <div className="a-third">
                        {typeof content["img-3"] !== "undefined" && content["img-3"] !== "" &&
                            <DisplayImageContainer
                                imageId={content["img-3"]}
                                size="small"
                                class="sxn-img"
                                isBgImage={true}
                            />
                        }

                        {content["title-3"] !== "" &&
                            <h3>{content["title-3"]}</h3>
                        }
                        {content["content-3"] !== "" &&
                            <div dangerouslySetInnerHTML={{ __html: content["content-3"] }}></div>
                        }
                    </div>
                }
            </div>
        </section>
    );
}

export default ThreeRow;
