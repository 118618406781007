import React from 'react';

import ExtractContent from '../../../utilities/extract-content';
import DisplayImageContainer from '../../../containers/images/display-image-container';

function ImgSide(props) {

    let content = ExtractContent.block(props.block);

    return (
        <section className="three-row">
            
            <div className="content narrow">
                {typeof content["title"] !== "undefined" && content["title"] !== "" &&
                    <h2>{content["title"]}</h2>
                }

                {(content["title-1"] !== "" || content["img-1"] !== "" || content["content-1"] !== "") &&
                    <div className="side-sml lft-side">
                        { typeof content["img-1"] !== "undefined" && content["img-1"] !== "" && 
                            <DisplayImageContainer
                                imageId={content["img-1"]}
                                size="small"
                                class="sxn-img"
                                isBgImage={true}
                            />
                        }

                        <div className="side-content">
                            { content["title-1"] !== "" &&
                                <h3>{content["title-1"]}</h3>
                            }
                            {content["content-1"] !== "" &&
                                <div dangerouslySetInnerHTML={{__html: content["content-1"]}}></div>
                            }
                        </div>
                    </div>
                }

                {(content["title-2"] !== "" || content["img-2"] !== "" || content["content-2"] !== "") &&
                    <div className="side-sml rght-side">
                        {typeof content["img-2"] !== "undefined" && content["img-2"] !== "" &&
                            <DisplayImageContainer
                                imageId={content["img-2"]}
                                size="small"
                                class="sxn-img"
                                isBgImage={true}
                            />
                        }

                        <div className="side-content">
                            {content["title-2"] !== "" &&
                                <h3>{content["title-2"]}</h3>
                            }
                            {content["content-2"] !== "" &&
                                <div dangerouslySetInnerHTML={{ __html: content["content-2"] }}></div>
                            }
                        </div>
                    </div>
                }
            </div>
        </section>
    );
}

export default ImgSide;
