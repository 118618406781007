import DisplayFile from '../../files/display-file';
import ExtractContent from './../../../utilities/extract-content';
import DonateAdvert from './sub-blocks/donate';

function InfoDonate(props) {

    let content = ExtractContent.block(props.block);
    if (!content) return "";

    return (
        <section className="plain">

            <div className="content">

                <div className="width-8">
                    { content.title && content.title !== "" && 
                        <h2>{ content.title }</h2>
                    }
                    { content.content && content.content !== "" && 
                        <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
                    }

                    <DisplayFile files={typeof content["files"] !== "undefined" ? content["files"] : []} />
                </div>

                <DonateAdvert content={content} class="width-4" />
            </div>
        </section>
    );
}

export default InfoDonate;
