import ExtractContent from './../../../utilities/extract-content';

function Video(props) {

    let content = ExtractContent.block(props.block, "draft");

    return (
        <section className="social-bar">

            <div className="content">

                <div className="width-12 centred video">

                    <div className="player" dangerouslySetInnerHTML={{ __html: content.code }}></div>            
                    
                </div>

            </div>
        </section>
    );
}

export default Video;
