// Components
import MenusContainer from './../../containers/pages/menus-container';

// Assets
import LwLogo from './../../assets/img/lw/livingwell-logo.svg'
import Facebook from './../../assets/img/lw/facebook.png';
import Twitter from './../../assets/img/lw/twitter.png';
import Instagram from './../../assets/img/lw/instagram.png';
import LinkedIn from './../../assets/img/lw/linkedin.png';
import WordingContainer from '../../containers/common/wording/wording-container';
import AqsLogo from './../../assets/img/lw/aqs-logo.png';

function Footer() {
    return (
        <footer className="final">
            <div className="content">
                <section className="width-10">

                    <nav className="footer">
                        <h3><WordingContainer wordName="footer-head-1" default="What we do" /></h3>
                        <ul>
                            <MenusContainer menuSlug="footer-what" />
                        </ul>
                    </nav>
                    <nav className="footer">
                        <h3><WordingContainer wordName="footer-head-2" default="Get involved" /></h3>
                        <ul>
                            <MenusContainer menuSlug="footer-involved" />
                        </ul>
                    </nav>
                    <nav className="footer">
                        <h3><WordingContainer wordName="footer-head-3" default="Legal" /></h3>
                        <ul>
                            <MenusContainer menuSlug="footer-legal" />
                        </ul>
                    </nav>

                </section>

                <section className="width-2">
                    <img src={LwLogo} alt="Living Well Bromley logo" className="logo" />
                    <img src={AqsLogo} alt="AQS logo" className="partner-logo" />
                </section>
            </div>

            <section className="content smallprint">
                <div className="width-8">
                    <p>All content and design copyright &copy; Living Well Bromley 2023</p>
                    <p>Registered charity number: 1157385</p>
                </div>
                <div className="width-4">
                    <ul className="social">
                        <li>
                            <a href="https://www.facebook.com/livingwellbromleyfoodbank" target="_blank" rel='noreferrer'><img src={Facebook} alt="Find us on Facebook" /></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/LWBromley" target="_blank" rel='noreferrer'><img src={Twitter} alt="Find us on Twitter" /></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/livingwellbromley" target="_blank" rel='noreferrer'><img src={Instagram} alt="Find us on Twitter" /></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/living-well-bromley1" target="_blank" rel='noreferrer'><img src={LinkedIn} alt="Find us on Twitter" /></a>
                        </li>
                    </ul>
                </div>
            </section>
        </footer>
    );
}

export default Footer;
